.page__service-detail {
  z-index: 8;
}

.page__overlay {
  z-index: 7;
}

.search-field__list-link:hover, .search-field__list-link:focus {
  cursor: pointer;
}
.search-field__list-link:not([data-id]):hover, .search-field__list-link:focus {
  cursor: default;
  color: #fff;
}

.search-field__submit {
  cursor: default;
}

.call-form__open-button {
  outline: none;
}

.main-block {
  transition: none;
}

.main-block__head, .main-block__footer {
  transition: transform 0.5s ease-out;
}

.main-block__body > * {
  transition: transform 0.5s ease-out;
}

.sing-up {
  align-items: flex-start;
  background-attachment: fixed;
  transition: none;
}

/* html {
  overflow-x: hidden;
  overflow-y: auto;
} */

.overlay {
  overflow: hidden;
}

.mobile-menu.is-open {
  position: fixed;
}

.tech-list__item {
  display: block;
  color: #fff;
}

.our-clients__item-image-wrap {
  display: inline-block;
}

/* .mobile-list__link:first-child:before {
  content: '00/'
} */

.scroll-button {
  position: absolute;
  right: 16px;
  left: auto;
  top: 80vh;
}

.awPageWrapper {
  position: relative;
}

@media screen and (min-width: 768px) {
  .scroll-button {
    right: 24px;
  }

  .awPageWrapper {
    position: static;
  }
}
@media screen and (min-width: 1200px) {
  .scroll-button {
    right: 32px;
  }
}
@media screen and (min-width: 1440px) {
  .scroll-button {
    right: 120px;
  }
}
@media screen and (min-width: 1600px) {
  .scroll-button {
    right: 200px;
  }
}

@keyframes videoFade {
  0% {opacity: 0;}
  80% {opacity: 0;}
  100% {opacity: 1;}
}


.main-block__video-wrapper {
  display: none;
  opacity: 0;
}

.main-block:first-child .main-block__video-wrapper {
  display: block;
  /* opacity: 0; */
}

.main-block__video-wrapper.is-active {
  opacity: 1;
  animation: 4s linear videoFade;
}

/* Анимация перехода по страницам */
.route-animation-enter,
.route-animation-exit {
  overflow: hidden;
  width: 100%;
}

.route-animation-enter {
  opacity: 1;
}

.route-animation-enter:before {
  content: '';
  display: inline-block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #1A1A1A;
  z-index: 10;
  animation: 2s cubic-bezier(0.2, 0.97, 0.97, 0.2) routeAnimation;
}

/* .route-animation-enter:after {
  content: '';
  display: inline-block;
  position: absolute;
  left: 100px;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #F7C449;
  z-index: 9;
  animation: 2s cubic-bezier(0.2, 0.97, 0.97, 0.2) routeAnimation;
} */

.route-animation-enter.route-animation-enter-active {
  opacity: 1;
}

.route-animation-enter.route-animation-enter-active:before {
  /* transform: translate3d(150%, 0, 0) skewX(-30deg); */
} 

.route-animation-enter.route-animation-enter-active:after {
  /* transform: translate3d(calc(150% - 50px), 0, 0) skewX(-30deg); */
} 


.route-animation-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: visible;
  transition-delay: 1.3s;
}

.route-animation-exit .content__main {
  min-height: 100vh;
  background-color: #000;
}

.route-animation-exit:after {
  content: '';
  display: inline-block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent url('../media/img/logo.svg') 50% 50% no-repeat;
  z-index: 11;
  opacity: 0;
  transform: translate3d(0, 0, 0);
  
}

.route-animation-exit-active {
  visibility: hidden;
}

.route-animation-exit-active:after {
  position: fixed;
  opacity: 0;
  z-index: 99;
  animation: 2s linear logoFade;
  transform: translate3d(0, 0, 0);
}


@keyframes routeAnimation {
  0% {transform: translate3d(-100%, 0, 0) skewX(30deg);}
  25% {transform: translate3d(0, 0, 0) skewX(0deg);}
  75% {transform: translate3d(0, 0, 0) skewX(0deg);}
  100% {transform: translate3d(150%, 0, 0) skewX(-30deg);}
}

@keyframes logoFade {
  0% {opacity: 0;}
  15% {opacity: 0;}
  25% {opacity: 1;}
  55% {opacity: 1;}
  65% {opacity: 0;}
  100% {opacity: 0;}
}


/* Анимация смены шагов */
.step-animation-enter,
.step-animation-exit {
  transition: 800ms ease-in;
}

.step-animation-enter {
  position: relative;
  /* left: 0; */
  /* width: 100%; */
  transform: translate3d(0, 100vh, 0);
}


.step-animation-enter.step-animation-enter-active {
  /* bottom: 10%; */
  transform: translate3d(0, 0, 0);
  /* bottom: 400px; */
}

.step-animation-exit {
  position: absolute;
  top: 0;
  /* left: 0; */
  /* width: 100%; */
  /* transition-delay: 0.5s; */
  transform: translateY(0);
}

.step-animation-exit-active {
  transform: translateY(-100vh);
}

/* Смена заголовков записи */
.title-animate-enter,
.title-animate-exit {
  transition: 500ms ease-in;
}

.title-animate-enter {
  opacity: 0;
  position: absolute;
  margin-top: -10px;
}

.title-animate-enter.title-animate-enter-active {
  opacity: 1;
}

.title-animate-exit {
  /* position: absolute; */
  opacity: 1;
}

.title-animate-exit-active {
  opacity: 0;
}


/* Смена количества шагов записи */
.step-info-enter,
.step-info-exit {
  transition: 500ms ease-in;
}

.step-info-enter {
  opacity: 0;
  position: absolute;
}


.step-info-enter.step-info-enter-active {
  opacity: 1;
}

.step-info-exit {
  opacity: 1;
}

.step-info-exit-active {
  opacity: 0;
}


/* Смена ссылок шагов */
.step-link-enter,
.step-link-exit {
  transition: 300ms ease-in;
}

.step-link-enter {
  opacity: 0;
  position: absolute;
}


.step-link-enter.step-link-enter-active {
  opacity: 1;
}

.step-link-exit {
  opacity: 1;
}

.step-link-exit-active {
  opacity: 0;
}

/* Всплывашка приложения */
.app-window {
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: #f2f2f2;
}
.app-window__container {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
}
.app-window__close-btn {
	display: inline-block;
	width: 8px;
	height: 8px;
	background-image: url('../media/img/icon/close.svg');
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 10px;
}
.app-window__logo-wrap {
	margin-right: 12px;
	width: 50px;
	height: 50px;
}
.app-window__logo-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.app-window__content {

}
.app-window__title {
	color: #000000;
	font-family: 'pt_sans-regular', sans-serif;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 2px;
}
.app-window__subtitle {
	color: #777777;
	font-family: 'pt_sans-regular', sans-serif;
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 2px;
}
.app-window__subtitle2 {
	color: #000000;
	font-family: 'pt_sans-regular', sans-serif;
	font-size: 12px;
	font-weight: 400;
}
.app-window__download-link {
	color: #3f9bf4;
	font-family: 'pt_sans-regular', sans-serif;
	font-size: 16px;
	font-weight: 400;
	margin-left: auto;
}