.date div {
  text-align: center;
}

.react-datepicker {
  background: rgba(22, 22, 22, 0.8);
  backdrop-filter: blur(14px);
  border-radius: 7px;
  border: 0;
}

.react-datepicker__header {
  background-color: transparent;
  border: 0;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-radius: 50%;
  color: #fff;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-radius: 50% !important;
  color: #fff;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
  border: 1px solid #4F4F4F;
  background-color: transparent ;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled {
  color: #fff;
  opacity: 0.5;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover {
  background: linear-gradient(108.09deg, #FFC230 36.78%, #FDB54A 77.9%);
  color: #000;
  font-weight: 500;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background: linear-gradient(108.09deg, #FFC230 36.78%, #FDB54A 77.9%);
  color: #000;
  font-weight: 500;
}

.react-datepicker__current-month {
  margin-bottom: 20px;
}

.react-datepicker__month {
  margin-top: 0;
}

.react-datepicker__navigation {
  /* position: relative; */
  top: 16px;
  border: 0;
  padding: 10px;
}

.react-datepicker__navigation--next:after {
  position: absolute;
  left: 0;
  top: calc(50% - 8px);
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #ccc;
}

.react-datepicker__navigation--previous:after {
  position: absolute;
  right: 0;
  top: calc(50% - 5px);
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 10px 5px 0;
  border-color: transparent #ccc transparent transparent;
}

.react-datepicker__header {
  padding-top: 15px;
}

@media screen and (min-width: 768px) {

  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 35px;
    height: 35px;
  }
}