@font-face{
  font-family:'Gotham Pro';
  src:url("../fonts/GothamPro.eot");
  src:local("Gotham Pro"), local("GothamPro"), url("../fonts/GothamPro.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro.woff2") format("woff2"), url("../fonts/GothamPro.woff") format("woff"), url("../fonts/GothamPro.ttf") format("truetype");
  font-weight:normal;
  font-style:normal; }

@font-face{
  font-family:'Gotham Pro';
  src:url("../fonts/GothamPro-Italic.eot");
  src:local("Gotham Pro Italic"), local("GothamPro-Italic"), url("../fonts/GothamPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Italic.woff2") format("woff2"), url("../fonts/GothamPro-Italic.woff") format("woff"), url("../fonts/GothamPro-Italic.ttf") format("truetype");
  font-weight:normal;
  font-style:italic; }

@font-face{
  font-family:'Gotham Pro';
  src:url("../fonts/GothamPro-Bold.eot");
  src:local("Gotham Pro Bold"), local("GothamPro-Bold"), url("../fonts/GothamPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Bold.woff2") format("woff2"), url("../fonts/GothamPro-Bold.woff") format("woff"), url("../fonts/GothamPro-Bold.ttf") format("truetype");
  font-weight:700;
  font-style:normal; }

@font-face{
  font-family:'Gotham Pro';
  src:url("../fonts/GothamPro-Medium.eot");
  src:local("Gotham Pro Medium"), local("GothamPro-Medium"), url("../fonts/GothamPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Medium.woff2") format("woff2"), url("../fonts/GothamPro-Medium.woff") format("woff"), url("../fonts/GothamPro-Medium.ttf") format("truetype");
  font-weight:500;
  font-style:normal; }
*,
*:before,
*:after{
  box-sizing:inherit; }

html{
  box-sizing:border-box; }

ul{
  list-style-type:none; }

h1{
  margin:0; }

html, body{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:25px;
  line-height:38px;
  min-height:100%;
  min-width:320px;
  margin:0;
  background-color:#000;
  line-height:1; }

@media screen and (min-width: 960px){
  .awPageWrapper{
    padding-top:0; } }

a{
  text-decoration:none; }
  a:hover{
    text-decoration:none; }

.globalWrapper{
  width:calc(100% - 30px);
  max-width:1220px;
  margin:0 auto; }
  @media screen and (min-width: 480px){
    .globalWrapper{
      width:calc(100% - 40px); } }
  @media screen and (min-width: 768px){
    .globalWrapper{
      width:calc(100% - 60px); } }

.globalWrapper_narrow{
  max-width:1106px; }

::-webkit-input-placeholder{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#fff; }
  @media screen and (min-width: 768px){
    ::-webkit-input-placeholder{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:16px;
      line-height:24px; } }

:-moz-placeholder{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#fff; }
  @media screen and (min-width: 768px){
    :-moz-placeholder{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:16px;
      line-height:24px; } }

::-moz-placeholder{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#fff; }
  @media screen and (min-width: 768px){
    ::-moz-placeholder{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:16px;
      line-height:24px; } }

:-ms-input-placeholder{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#fff; }
  @media screen and (min-width: 768px){
    :-ms-input-placeholder{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:16px;
      line-height:24px; } }

@media screen and (min-width: 960px){
  ::-webkit-input-placeholder{
    font-size:14px; }
  :-moz-placeholder{
    font-size:14px; }
  ::-moz-placeholder{
    font-size:14px; }
  :-ms-input-placeholder{
    font-size:14px; } }

.fa{
  display:inline-block;
  font:normal normal normal 14px/1 FontAwesome;
  font-size:inherit;
  text-rendering:auto;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale; }

.mCS-default.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
  width:5px;
  background-color:#828282;
  border-radius:10px; }

.mCS-default.mCSB_scrollTools .mCSB_draggerRail{
  background-color:transparent; }

.awPageWrapper{
  overflow:hidden; }
.user-typography{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#fff; }
  .user-typography h1, .user-typography h2, .user-typography h3, .user-typography h4, .user-typography h5, .user-typography h6,
  .user-typography .h1, .user-typography .h2, .user-typography .h3, .user-typography .h4, .user-typography .h5, .user-typography .h6{
    margin-bottom:10px; }
    .user-typography h1:not(:first-child), .user-typography h2:not(:first-child), .user-typography h3:not(:first-child), .user-typography h4:not(:first-child), .user-typography h5:not(:first-child), .user-typography h6:not(:first-child),
    .user-typography .h1:not(:first-child), .user-typography .h2:not(:first-child), .user-typography .h3:not(:first-child), .user-typography .h4:not(:first-child), .user-typography .h5:not(:first-child), .user-typography .h6:not(:first-child){
      margin-top:40px; }
  .user-typography h1, .user-typography .h1,
  .user-typography h2, .user-typography .h2,
  .user-typography h3, .user-typography .h3{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:28px;
    line-height:35px; }
  .user-typography h4, .user-typography .h4,
  .user-typography h5, .user-typography .h5,
  .user-typography h6, .user-typography .h6{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:500;
    font-size:20px;
    line-height:22px; }
  .user-typography p, .user-typography .p{
    margin-bottom:10px; }
  .user-typography ol, .user-typography .ol,
  .user-typography ul, .user-typography .ul{
    margin-top:30px;
    margin-bottom:30px; }
  .user-typography ol, .user-typography .ol{
    counter-reset:ol-counter; }
  .user-typography ol li, .user-typography .ol li,
  .user-typography ul li, .user-typography .ul li{
    margin-bottom:40px; }
  .user-typography ol li, .user-typography .ol li{
    position:relative;
    list-style:none;
    padding-left:35px; }
    .user-typography ol li:before, .user-typography .ol li:before{
      content:"";
      position:absolute;
      left:0;
      top:50%;
      transform:translateY(-50%);
      background-repeat:no-repeat;
      background-position:center;
      background-size:contain; }
    .user-typography ol li:before, .user-typography .ol li:before{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:28px;
      line-height:35px;
      content:counter(ol-counter) ".";
      counter-increment:ol-counter;
      top:9px;
      color:#F7C449; }
  .user-typography ul li, .user-typography .ul li{
    position:relative;
    list-style:none;
    padding-left:35px; }
    .user-typography ul li:before, .user-typography .ul li:before{
      content:"";
      position:absolute;
      left:0;
      top:50%;
      transform:translateY(-50%);
      background-repeat:no-repeat;
      background-position:center;
      background-size:contain; }
    .user-typography ul li:before, .user-typography .ul li:before{
      top:9px;
      width:8px;
      height:8px;
      border-radius:50%;
      background-color:#F7C449; }
  .user-typography .text-small{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:12px;
    line-height:16px; }

@media screen and (min-width: 480px){
  .user-typography h1, .user-typography .h1,
  .user-typography h2, .user-typography .h2,
  .user-typography h3, .user-typography .h3{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px; }
  .user-typography h4, .user-typography .h4,
  .user-typography h5, .user-typography .h5{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:28px;
    line-height:35px; }
  .user-typography h6, .user-typography .h6{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:500;
    font-size:20px;
    line-height:22px; } }

@media screen and (min-width: 600px){
  .user-typography{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; }
    .user-typography h1, .user-typography .h1{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:normal;
      font-size:72px;
      line-height:66px; }
    .user-typography h2, .user-typography .h2{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:normal;
      font-size:52px;
      line-height:56px; }
    .user-typography h3, .user-typography .h3{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:normal;
      font-size:46px;
      line-height:59px; }
    .user-typography h4, .user-typography .h4{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:normal;
      font-size:36px;
      line-height:44px; }
    .user-typography h5, .user-typography .h5{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:normal;
      font-size:28px;
      line-height:35px; }
    .user-typography h6, .user-typography .h6{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:500;
      font-size:20px;
      line-height:22px; }
    .user-typography .text-small{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:14px;
      line-height:18px; } }

@media screen and (min-width: 768px){
  .user-typography{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:18px;
    line-height:25px; }
    .user-typography h1:not(:first-child), .user-typography h2:not(:first-child), .user-typography h3:not(:first-child), .user-typography h4:not(:first-child), .user-typography h5:not(:first-child), .user-typography h6:not(:first-child),
    .user-typography .h1:not(:first-child), .user-typography .h2:not(:first-child), .user-typography .h3:not(:first-child), .user-typography .h4:not(:first-child), .user-typography .h5:not(:first-child), .user-typography .h6:not(:first-child){
      margin-top:60px; } }

.container{
  margin-left:auto;
  margin-right:auto;
  padding-left:16px;
  padding-right:16px;
  width:100%;
  max-width:100%; }

@media screen and (min-width: 768px){
  .container{
    padding-left:24px;
    padding-right:24px; } }

@media screen and (min-width: 1200px){
  .container{
    padding-left:32px;
    padding-right:32px;
    width:1200px; } }

@media screen and (min-width: 1440px){
  .container{
    padding-left:120px;
    padding-right:120px;
    width:1440px; } }

@media screen and (min-width: 1600px){
  .container{
    padding-left:230px;
    padding-right:230px;
    width:1920px; } }

.desktop-menu{
  display:flex;
  align-items:center; }
  @media screen and (min-width: 1200px){
    .desktop-menu{
      border-top:1px solid rgba(255, 255, 255, 0.3); } }

.desktop-menu__item{
  position:relative; }
  @media screen and (min-width: 768px){
    .desktop-menu__item:nth-child(n + 4){
      display:none; } }
  @media screen and (min-width: 1200px){
    .desktop-menu__item:nth-child(n + 4){
      display:inline-block; } }

.desktop-menu__link{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  display:inline-block;
  padding:0 9px;
  color:#fff;
  text-decoration:none;
  cursor:pointer;
  transition:all 0.1s linear; }
  @media screen and (min-width: 768px){
    .desktop-menu__link{
      padding:9px; } }
  @media screen and (min-width: 1200px){
    .desktop-menu__link{
      padding-bottom:0; } }
  .desktop-menu__link.is-active{
    position:relative; }
    .desktop-menu__link.is-active:before{
      content:'';
      position:absolute;
      top:0;
      right:9px;
      display:inline-block;
      width:calc(100% - 23px);
      height:3px;
      background:#F7C449; }

.desktop-menu__link_drop:after{
  content:'';
  display:inline-block;
  width:8px;
  height:8px;
  margin-left:5px;
  background:transparent url("../img/icon/drop-bottom.svg") 50% 50% no-repeat;
  transition:all 0.1s linear; }

.desktop-menu__link_drop:hover:after{
  background:transparent url("../img/icon/drop-bottom-hover.svg") 50% 50% no-repeat; }

.desktop-menu__submenu{
  display:none;
  position:absolute;
  top:37px;
  left:12px;
  flex-direction:column;
  padding:12px 20px;
  background-color:#333;
  border-radius:4px;
  box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index:2;
  opacity:0;
  -webkit-animation:0.2s linear fadeSubmenu;
          animation:0.2s linear fadeSubmenu; }
  .desktop-menu__submenu:after{
    content:'';
    position:absolute;
    top:-9px;
    left:0;
    width:100%;
    height:20px; }

.desktop-menu__item:hover .desktop-menu__submenu{
  display:flex;
  opacity:1; }

.desktop-menu__sublink{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  display:block;
  padding:7px 0;
  color:#fff;
  text-decoration:none;
  white-space:nowrap; }
  .desktop-menu__sublink span{
    color:#F7C449; }

@-webkit-keyframes fadeSubmenu{
  0%{
    opacity:0; }
  100%{
    opacity:1; } }

@keyframes fadeSubmenu{
  0%{
    opacity:0; }
  100%{
    opacity:1; } }

.main-block{
  position:relative;
  width:calc(100% - 20px);
  min-height:200px;
  margin:10px 7px;
  background:#000 url(../img/components/main-block/bg-1.jpg) 50% 50% no-repeat;
  background-size:cover;
  border-radius:7px; }
  @media screen and (min-width: 480px){
    .main-block{
      margin:10px 8px;
      min-height:227px; } }
  @media screen and (min-width: 768px){
    .main-block{
      display:flex;
      align-items:flex-end;
      justify-content:flex-start;
      flex-shrink:0;
      width:80%;
      height:100%;
      max-width:1300px;
      margin:0;
      overflow:hidden;
      transition:1s ease-out;
      border-radius:0; } }
  .main-block:hover .main-block__head{
    transform:translateY(0); }
  .main-block:hover .main-block__body > *{
    opacity:1;
    transform:translateY(0); }
  .main-block:hover .main-block__footer{
    transform:translateY(0); }
  .main-block:first-child{
    padding-top:155px;
    padding-bottom:45px;
    margin:0;
    width:100%; }
    @media screen and (min-width: 768px){
      .main-block:first-child{
        padding:0; } }
    .main-block:first-child .main-block__body{
      max-width:none; }
  .main-block:last-child{
    margin-bottom:0; }

.main-block__wrapper{
  width:100%;
  max-width:1220px;
  margin:0 auto;
  padding:12px 9px;
  border-radius:7px; }
  @media screen and (min-width: 480px){
    .main-block__wrapper{
      padding:16px 10px; } }
  @media screen and (min-width: 600px){
    .main-block__wrapper{
      padding:16px 12px;
      height:100%; } }
  @media screen and (min-width: 768px){
    .main-block__wrapper{
      width:calc(100% - 165px);
      height:auto;
      border-radius:0; } }

.main-block__content{
  color:#fff; }
  @media screen and (min-width: 600px){
    .main-block__content{
      height:100%; } }
  @media screen and (min-width: 768px){
    .main-block__content{
      margin-bottom:10%;
      height:auto; } }

.main-block__head{
  margin-bottom:10px; }
  @media screen and (min-width: 768px){
    .main-block__head{
      margin-bottom:25px;
      transform:translateY(100px);
      transition:0.5s ease-out; } }

.main-block__body{
  max-width:420px; }
  @media screen and (min-width: 768px){
    .main-block__body{
      overflow:hidden; }
      .main-block__body > *{
        opacity:0;
        transform:translateY(100%);
        transition:0.5s ease-out; } }

.main-block__subtitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  margin-bottom:4px;
  color:#F7C449; }
  @media screen and (min-width: 768px){
    .main-block__subtitle{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:500;
      font-size:20px;
      line-height:22px;
      margin-bottom:10px; } }

.main-block__subtitle_count span{
  display:none; }
  @media screen and (min-width: 768px){
    .main-block__subtitle_count span{
      display:inline-flex;
      align-items:center;
      justify-content:center;
      width:25px;
      height:25px;
      margin-right:8px;
      border-radius:50%;
      color:#000;
      background-color:#F7C449; } }

.main-block__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:22px; }
  @media screen and (min-width: 768px){
    .main-block__title{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:53px;
      line-height:59px; } }

.main-block__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:14px;
  display:inline-block; }
  @media screen and (min-width: 768px){
    .main-block__text{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:20px;
      line-height:29px; } }

.main-block__app{
  width:107px;
  position:absolute;
  right:30px;
  bottom:0; }
  @media screen and (min-width: 480px){
    .main-block__app{
      right:20%; } }
  @media screen and (min-width: 600px){
    .main-block__app{
      width:110px;
      right:25px; } }
  @media screen and (min-width: 768px){
    .main-block__app{
      display:none; } }
  @media screen and (min-width: 1200px){
    .main-block__app{
      display:block;
      width:auto;
      right:10%; } }
  @media screen and (min-width: 1440px){
    .main-block__app{
      right:20%; } }

.main-block__footer{
  margin-top:20px; }
  @media screen and (min-width: 600px){
    .main-block__footer{
      display:none; } }
  @media screen and (min-width: 768px){
    .main-block__footer{
      display:flex;
      margin-top:36px;
      transform:translateY(-30px);
      transition:0.5s ease-out; } }

.main-block__prev-button{
  display:none; }
  @media screen and (min-width: 768px){
    .main-block__prev-button{
      position:absolute;
      width:50px;
      height:100%;
      background:transparent;
      cursor:url("../img/icon/cur-2.cur"), auto;
      cursor:url("../img/icon/cur-2.png") 53 53, crosshair; } }

.main-block{
  cursor:url("../img/icon/cur-2.cur"), auto;
  cursor:url("../img/icon/cur-2.png") 53 53, crosshair; }
  .main-block.is-active + .main-block{
    cursor:url("../img/icon/cur.cur"), auto;
    cursor:url("../img/icon/cur.png") 53 53, crosshair; }
  .main-block:first-child .main-block__prev-button{
    display:none !important; }
  .main-block:last-child .main-block__prev-button{
    display:none !important; }
  .main-block.is-active{
    cursor:default; }
    .main-block.is-active .main-block__prev-button{
      display:block; }
    .main-block.is-active .main-block__head{
      transform:translateY(0); }
    .main-block.is-active .main-block__body > *{
      transform:translateY(0);
      opacity:1; }
    .main-block.is-active .main-block__footer{
      transform:translateY(0); }

.main-block__video-wrapper{
  display:none; }
  @media screen and (min-width: 768px){
    .main-block__video-wrapper{
      position:absolute;
      top:0;
      left:0;
      display:block;
      overflow:hidden;
      z-index:0;
      width:100%;
      height:100%; } }

.main-block__video{
  display:none; }
  @media screen and (min-width: 768px){
    .main-block__video{
      display:block;
      min-height:100%;
      min-width:100%;
      pointer-events:none; } }

.head-dashboard{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  z-index:5; }
  .head-dashboard .desktop-menu{
    display:none; }
    @media screen and (min-width: 768px){
      .head-dashboard .desktop-menu{
        display:flex; } }
  .head-dashboard .hamburger{
    margin-left:18px; }
    @media screen and (min-width: 768px){
      .head-dashboard .hamburger{
        margin-left:50px; } }
    @media screen and (min-width: 1200px){
      .head-dashboard .hamburger{
        margin-left:64px; } }
  @media screen and (min-width: 768px){
    .head-dashboard .logo{
      margin-right:31px; } }
  @media screen and (min-width: 1200px){
    .head-dashboard .logo{
      margin-right:25px; } }

.head-dashboard__wrapper{
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:18px 16px;
  background:rgba(26, 26, 26, 0.6);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px); }
  @media screen and (min-width: 768px){
    .head-dashboard__wrapper{
      padding:32px 24px;
      padding-bottom:0;
      background:transparent;
      -webkit-backdrop-filter:none;
              backdrop-filter:none; } }
  @media screen and (min-width: 1200px){
    .head-dashboard__wrapper{
      padding:46px 55px;
      padding-bottom:0; } }

.head-dashboard__group{
  display:flex;
  align-items:center; }

.head-dashboard_inner{
  position:absolute; }

.head-dashboard_inner .head-dashboard__wrapper{
  margin-left:auto;
  margin-right:auto;
  padding-left:16px;
  padding-right:16px;
  width:100%;
  max-width:100%; }
  @media screen and (min-width: 768px){
    .head-dashboard_inner .head-dashboard__wrapper{
      padding-left:24px;
      padding-right:24px; } }
  @media screen and (min-width: 1200px){
    .head-dashboard_inner .head-dashboard__wrapper{
      padding-left:32px;
      padding-right:32px;
      width:1200px; } }
  @media screen and (min-width: 1440px){
    .head-dashboard_inner .head-dashboard__wrapper{
      padding-left:70px;
      padding-right:70px;
      width:1440px; } }
  @media screen and (min-width: 1600px){
    .head-dashboard_inner .head-dashboard__wrapper{
      padding-left:200px;
      padding-right:200px;
      width:1920px; } }

.hamburger{
  display:flex;
  align-items:center;
  padding:11px 5px;
  outline:none;
  border-radius:50%;
  cursor:pointer;
  transition:all 0.1s linear; }
  @media screen and (min-width: 768px){
    .hamburger{
      position:fixed;
      width:32px;
      height:32px;
      background:#F7C449 url(../img/icon/hamburger-small.svg) 50% 50% no-repeat;
      padding:15px 9px; } }
  @media screen and (min-width: 1200px){
    .hamburger{
      width:46px;
      height:46px;
      background:#F7C449 url(../img/icon/hamburger.svg) 50% 50% no-repeat;
      padding:21px 10px; } }
  .hamburger:hover{
    opacity:0.7; }

.hamburger__icon{
  position:relative;
  display:inline-block;
  vertical-align:middle;
  width:14px;
  height:2px;
  background-color:#F7C449;
  transition:all 0.3s ease; }
  @media screen and (min-width: 768px){
    .hamburger__icon{
      display:none; } }

.hamburger__icon:before{
  content:'';
  position:absolute;
  left:0;
  top:-4px;
  display:inline-block;
  width:14px;
  height:2px;
  background-color:#F7C449;
  transition:all 0.1s linear; }
  @media screen and (min-width: 768px){
    .hamburger__icon:before{
      display:none; } }

.hamburger__icon:after{
  content:'';
  position:absolute;
  left:0;
  bottom:-4px;
  display:inline-block;
  width:14px;
  height:2px;
  background-color:#F7C449;
  transition:all 0.1s linear; }
  @media screen and (min-width: 768px){
    .hamburger__icon:after{
      display:none; } }

.contact-list{
  text-align:left; }

.contact-list__item{
  display:block; }

.contact-list__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:18px;
  line-height:25px;
  display:block;
  color:#F7C449;
  white-space:nowrap; }

.contact-list__value{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  display:block;
  color:#fff;
  white-space:nowrap; }

.contact-list_dark{
  background-color:rgba(0, 0, 0, 0.85);
  border-radius:4px;
  box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25); }
  .contact-list_dark .contact-list__item{
    padding:12px 20px;
    padding-right:60px;
    background:transparent url(../img/icon/arrow-right.svg) calc(100% - 20px) 50% no-repeat; }
    .contact-list_dark .contact-list__item:hover{
      opacity:0.8;
      transition:all 0.1s linear; }
  .contact-list_dark .contact-list__item + .contact-list__item{
    border-top:1px solid #000; }

.contact-list_black .contact-list__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  margin-bottom:5px;
  color:#828282; }
  @media screen and (min-width: 768px){
    .contact-list_black .contact-list__title{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:18px;
      line-height:25px;
      margin-bottom:3px; } }

.contact-list_black .contact-list__value{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:25px;
  line-height:38px;
  color:#333; }
  @media screen and (min-width: 768px){
    .contact-list_black .contact-list__value{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:28px;
      line-height:35px; } }

.contact-list_black .contact-list__item{
  margin-bottom:15px; }

.phone-button{
  position:relative;
  width:24px;
  height:24px;
  background:transparent url(../img/icon/phone-mini.svg) 50% 50% no-repeat;
  cursor:pointer;
  transition:all 0.1s linear;
  outline:none;
  flex-shrink:0; }
  @media screen and (min-width: 768px){
    .phone-button{
      width:32px;
      height:32px;
      margin-right:50px;
      background:transparent url(../img/icon/phone-button.svg) 50% 50% no-repeat;
      background-size:contain; } }
  @media screen and (min-width: 1200px){
    .phone-button{
      width:46px;
      height:46px;
      margin-right:64px; } }
  @media screen and (min-width: 768px){
    .phone-button:hover{
      background:transparent url(../img/icon/phone-button-hover.svg) 50% 50% no-repeat;
      background-size:contain; } }
  .phone-button:hover .contact-list{
    display:block;
    opacity:1; }
  .phone-button .contact-list{
    display:none;
    position:absolute;
    top:calc(100% + 24px);
    right:0;
    z-index:1;
    opacity:0;
    -webkit-animation:0.2s linear fadeSubmenu;
            animation:0.2s linear fadeSubmenu; }
    .phone-button .contact-list:after{
      content:'';
      position:absolute;
      top:-30px;
      left:0;
      width:100%;
      height:30px; }

@keyframes fadeSubmenu{
  0%{
    opacity:0; }
  100%{
    opacity:1; } }

.logo{
  width:100px; }
  @media screen and (min-width: 768px){
    .logo{
      width:124px; } }
  @media screen and (min-width: 1200px){
    .logo{
      width:174px; } }

.logo__img{
  width:100%; }

.mobile-menu{
  position:fixed;
  right:0;
  top:0;
  width:100%;
  max-width:500px;
  height:100%;
  background:#fff url(../img/components/mobile-menu/bg-mobile.svg) 0 0 no-repeat;
  overflow:auto;
  overflow-x:hidden;
  transform:translateX(100%);
  transition:transform 0.3s;
  z-index:9; }
  @media screen and (min-width: 768px){
    .mobile-menu{
      right:-15px;
      max-width:900px;
      background:#fff url(../img/components/mobile-menu/bg.svg) 0 0 no-repeat; } }
  .mobile-menu.is-open{
    transform:translateX(0); }
  @media screen and (min-width: 768px){
    .mobile-menu .little-menu{
      display:none; } }
  .mobile-menu .contact-list__item{
    display:inline-block;
    min-width:51%; }

.mobile-menu__wrapper{
  padding:87px 16px 22px 16px; }
  @media screen and (min-width: 768px){
    .mobile-menu__wrapper{
      padding:70px 66px 49px 125px; } }

.mobile-menu__close{
  position:absolute;
  top:27px;
  right:16px;
  width:32px;
  height:32px;
  background:#F7C449 url(../img/icon/close.svg) 50% 50% no-repeat;
  border-radius:50%;
  cursor:pointer;
  outline:none; }
  @media screen and (min-width: 768px){
    .mobile-menu__close{
      top:70px;
      right:66px;
      width:46px;
      height:46px; } }
  .mobile-menu__close:hover:after{
    width:308px;
    height:308px; }
  .mobile-menu__close:after{
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    display:inline-block;
    width:114px;
    height:114px;
    border:1px solid #F7C449;
    border-radius:50%;
    transition:all 0.3s; }
    @media screen and (min-width: 768px){
      .mobile-menu__close:after{
        width:308px;
        height:308px; } }
    @media screen and (min-width: 1200px){
      .mobile-menu__close:after{
        width:0;
        height:0; } }

.mobile-menu__body{
  padding-bottom:13px; }

.mobile-menu__footer{
  position:relative;
  padding-top:33px;
  border-top:1px solid rgba(0, 0, 0, 0.1); }
  @media screen and (min-width: 768px){
    .mobile-menu__footer{
      border-top:0;
      padding:0; } }
  .mobile-menu__footer .button{
    margin-top:40px; }

.mobile-list{
  counter-reset:num -1;
  margin-bottom:38px; }
  @media screen and (min-width: 768px){
    .mobile-list{
      margin-bottom:50px; } }

.mobile-list__item{
  margin-bottom:20px; }
  @media screen and (min-width: 768px){
    .mobile-list__item{
      margin-bottom:16px; } }

.mobile-list__link{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:25px;
  line-height:38px;
  display:inline-flex;
  align-items:flex-start;
  color:#292929; }
  @media screen and (min-width: 768px){
    .mobile-list__link{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:36px;
      line-height:44px; } }
  .mobile-list__link:before{
    content:"0" counter(num) "/";
    counter-increment:num;
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:18px;
    margin-top:8px;
    margin-right:10px;
    color:#707070; }
    @media screen and (min-width: 768px){
      .mobile-list__link:before{
        font-family:Gotham Pro, sans-serif;
        font-style:normal;
        font-weight:400;
        font-size:20px;
        line-height:18px;
        line-height:29px; } }

@media screen and (min-width: 768px){
  .little-menu{
    -moz-column-count:2;
         column-count:2;
    -moz-column-gap:0;
         column-gap:0; } }

.little-menu__item{
  padding:7px 0; }
  @media screen and (min-width: 768px){
    .little-menu__item:last-child .little-menu__link{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:18px;
      line-height:25px; } }

.little-menu__link{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  display:inline-block;
  padding-left:38px;
  color:#333;
  opacity:0.8; }
  @media screen and (min-width: 768px){
    .little-menu__link{
      white-space:nowrap;
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:19px;
      line-height:1.1; } }
  .little-menu__link span{
    font-weight:500; }

.button{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  display:inline-block;
  padding:9px 16px;
  color:#292929;
  background-color:#F7C449;
  border:2px solid transparent;
  border-radius:6px;
  text-align:center;
  cursor:pointer;
  transition:all 0.1s linear; }
  @media screen and (min-width: 768px){
    .button{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:20px;
      line-height:18px;
      padding:14px 30px; } }
  .button:hover, .button:focus{
    background-color:#FDC02E; }
  .button.is-disable{
    opacity:0.5;
    cursor:not-allowed; }

.button_full{
  width:100%; }

.button_theme_gray{
  background-color:rgba(189, 189, 189, 0.2);
  -webkit-backdrop-filter:blur(9px);
          backdrop-filter:blur(9px);
  color:#fff; }
  .button_theme_gray:hover, .button_theme_gray:focus{
    background-color:rgba(130, 130, 130, 0.2); }

.button_theme_white{
  background-color:#fff;
  color:#292929; }
  .button_theme_white:hover, .button_theme_white:focus{
    background-color:#f2f2f2; }

.button_text{
  background:transparent;
  color:#fff; }
  .button_text:hover, .button_text:focus{
    background:transparent;
    color:#F7C449; }

.button_sz_sm{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  padding:9px 5px; }

.button_sz_md{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:18px;
  padding:14px 30px; }

.button_pd_sm{
  padding:5px; }

.button_pdv_sm{
  padding-top:5px;
  padding-bottom:5px; }

.button_fz_sm{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px; }

.button_fz_md{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:18px; }

.button_cl_black{
  color:#000; }

.button__subtext{
  color:#4F4F4F; }

.button-2{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:18px;
  display:inline-block;
  padding:14px 5px;
  border:2px solid #F7C449;
  border-radius:4px;
  color:#F7C449;
  text-align:center;
  cursor:pointer;
  transition:all 0.1s linear; }
  .button-2:hover, .button-2:focus{
    border-color:#FDC02E; }
  .button-2_theme_white{
    border-color:rgba(242, 242, 242, 0.3);
    color:#fff; }
    .button-2_theme_white:hover, .button-2_theme_white:focus{
      border-color:#808080; }
  .button-2_theme_gray{
    border-color:#E0E0E0;
    color:#333333; }
    .button-2_theme_gray:hover, .button-2_theme_gray:focus{
      border-color:#808080; }
  .button-2_sz_sm{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:18px;
    padding-top:9px;
    padding-bottom:9px; }
  .button-2_pd_sm{
    padding:5px; }
  .button-2_pdv_sm{
    padding-top:5px;
    padding-bottom:5px; }
  .button-2_fz_sm{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:18px; }

.address-list{
  display:inline-flex;
  flex-direction:column;
  width:100%;
  max-width:302px; }
  @media screen and (min-width: 600px){
    .address-list{
      max-width:390px; } }
  @media screen and (min-width: 768px){
    .address-list{
      max-width:445px; } }

.address-list__item{
  position:relative;
  display:inline-block;
  width:100%;
  padding:11px 13px;
  padding-right:45px;
  background:rgba(24, 24, 24, 0.7);
  border-radius:8px;
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  color:#fff;
  transition:all 0.1s linear;
  line-height:0; }
  @media screen and (min-width: 768px){
    .address-list__item{
      padding:8px 18px;
      padding-left:56px;
      padding-right:45px;
      border-radius:20px; } }
  .address-list__item:hover{
    color:#000;
    background-color:#F7C449; }
    .address-list__item:hover:after{
      background:transparent url(../img/icon/arrow-right-black.svg) 50% 50% no-repeat; }
    .address-list__item:hover:before{
      background:transparent url(../img/icon/pin-white.svg) 50% 50% no-repeat; }
  .address-list__item:after{
    content:'';
    position:absolute;
    right:13px;
    top:50%;
    display:inline-block;
    width:20px;
    height:15px;
    background:transparent url(../img/icon/arrow-right.svg) 50% 50% no-repeat;
    transform:translateY(-50%); }
    @media screen and (min-width: 768px){
      .address-list__item:after{
        right:18px; } }
  .address-list__item:before{
    content:'';
    display:none;
    position:absolute;
    left:18px;
    top:15px;
    width:17px;
    height:26px;
    background:transparent url(../img/icon/pin.svg) 50% 50% no-repeat; }
    @media screen and (min-width: 768px){
      .address-list__item:before{
        display:inline-block; } }

.address-list__item + .address-list__item{
  margin-top:8px; }

.address-list__body{
  display:inline-block;
  line-height:0; }

.address-list__subtitle{
  display:none; }
  @media screen and (min-width: 768px){
    .address-list__subtitle{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:16px;
      line-height:24px;
      display:block;
      color:#828282; } }

.address-list__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  display:block; }
  @media screen and (min-width: 768px){
    .address-list__title{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:25px;
      line-height:38px; } }

.content__main_horizontal{
  width:100%;
  padding-bottom:20px;
  background-color:#000; }
  @media screen and (min-width: 600px){
    .content__main_horizontal{
      display:flex;
      flex-wrap:wrap; } }
  @media screen and (min-width: 768px){
    .content__main_horizontal{
      position:fixed;
      top:0;
      left:0;
      flex-wrap:nowrap;
      width:100%;
      height:100%;
      padding-bottom:0;
      overflow:scroll; } }
  @media screen and (min-width: 600px){
    .content__main_horizontal .main-block{
      width:calc(50% - 18px); } }
  @media screen and (min-width: 600px) and (min-width: 768px){
    .content__main_horizontal .main-block{
      width:80%; } }
  @media screen and (min-width: 600px){
    .content__main_horizontal .main-block:first-child{
      width:100%; } }
  @media screen and (min-width: 768px){
    .content__main_horizontal .main-block:first-child{
      width:80%; } }
  @media screen and (min-width: 600px){
    .content__main_horizontal .main-block:last-child{
      width:100%; } }
  @media screen and (min-width: 768px){
    .content__main_horizontal .main-block:last-child{
      width:80%; } }

.content__main_horizontal::-webkit-scrollbar{
  display:none; }

.tech-list__item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  line-height:14px; }
  @media screen and (min-width: 768px){
    .tech-list__item{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:20px;
      line-height:29px; } }
  .tech-list__item span{
    color:#F7C449; }

.tech-list__item + .tech-list__item{
  margin-top:6px; }

.hashtags{
  -moz-column-count:1;
       column-count:1;
  -moz-column-gap:0;
       column-gap:0; }
  @media screen and (min-width: 768px){
    .hashtags{
      -moz-column-count:2;
           column-count:2; } }

.hashtags__item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:14px; }
  @media screen and (min-width: 768px){
    .hashtags__item{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:20px;
      line-height:29px; } }
  .hashtags__item span{
    color:#F7C449; }

.sing-up{
  position:relative;
  width:100%;
  min-height:100vh;
  padding-top:90px;
  background:#000 url(../img/components/main-block/bg-1.jpg) 50% 50% no-repeat;
  background-size:cover; }
  @media screen and (min-width: 768px){
    .sing-up{
      display:flex;
      align-items:flex-end;
      justify-content:flex-start;
      flex-shrink:0;
      width:100%;
      padding-top:180px;
      background:#000 url(../img/components/main-block/bg-1.jpg) 50% 50% no-repeat;
      background-size:cover;
      transition:1s ease-out;
      border-radius:0; } }

.sing-up__wrapper{
  width:100%;
  max-width:320px;
  margin:0 auto;
  padding:0 9px;
  border-radius:7px; }
  @media screen and (min-width: 600px){
    .sing-up__wrapper{
      margin-bottom:6.5%; } }
  @media screen and (min-width: 768px){
    .sing-up__wrapper{
      width:calc(100% - 165px);
      height:auto;
      border-radius:0;
      max-width:none; } }

.sing-up__content{
  color:#fff; }

.sing-up__head{
  margin-bottom:10px; }
  @media screen and (min-width: 768px){
    .sing-up__head{
      margin-bottom:25px; } }

.sing-up__subtitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  margin-bottom:4px; }
  @media screen and (min-width: 768px){
    .sing-up__subtitle{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:500;
      font-size:20px;
      line-height:22px;
      margin-bottom:10px; } }

.sing-up__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:22px; }
  @media screen and (min-width: 768px){
    .sing-up__title{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:53px;
      line-height:59px; } }

.sing-up__suptitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:18px;
  line-height:25px;
  margin-top:20px;
  max-width:460px; }
  @media screen and (min-width: 768px){
    .sing-up__suptitle{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:25px;
      line-height:38px; } }

.sing-up__step{
  display:none;
  padding-bottom:100px; }
  @media screen and (min-width: 768px){
    .sing-up__step{
      padding-bottom:0; } }
  .sing-up__step.is-active{
    display:block; }

.sing-up__footer{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  padding:16px;
  background:rgba(10, 10, 11, 0.8); }
  @media screen and (min-width: 768px){
    .sing-up__footer{
      position:static;
      width:auto;
      margin-top:32px;
      padding:0;
      background:transparent; } }
  .sing-up__footer .button{
    width:100%; }
    @media screen and (min-width: 768px){
      .sing-up__footer .button{
        width:auto; } }
  .sing-up__footer .button_text{
    display:none; }
    @media screen and (min-width: 768px){
      .sing-up__footer .button_text{
        display:inline-block; } }

.sing-up__footer-wrap{
  width:100%;
  max-width:320px;
  margin:0 auto;
  padding:0 9px; }
  @media screen and (min-width: 768px){
    .sing-up__footer-wrap{
      max-width:none;
      margin:0;
      padding:0; } }

.step-info{
  margin-bottom:10px; }
  @media screen and (min-width: 768px){
    .step-info{
      margin-bottom:15px; } }

.step-info__progress{
  display:flex;
  flex-wrap:wrap;
  align-items:center; }
  @media screen and (min-width: 768px){
    .step-info__progress{
      margin-bottom:15px; } }

.step-info__name{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  margin-right:5px;
  color:#fff;
  line-height:1;
  opacity:0.8; }
  @media screen and (min-width: 768px){
    .step-info__name{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:20px;
      line-height:18px;
      margin-right:8px;
      opacity:0.4;
      vertical-align:middle; } }

.step-info__value{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  color:#fff;
  opacity:0.8;
  line-height:1; }
  @media screen and (min-width: 768px){
    .step-info__value{
      opacity:1;
      vertical-align:middle; } }

.step-info__list{
  display:none; }
  @media screen and (min-width: 768px){
    .step-info__list{
      display:flex;
      flex-wrap:wrap; } }

.step-info__item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  display:flex;
  align-items:center;
  margin-right:12px;
  margin-bottom:12px;
  padding:7px 14px;
  border-radius:8px;
  color:#fff;
  background:rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  transition:all 0.1s linear; }
  .step-info__item span{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:12px;
    line-height:24px;
    line-height:14px;
    margin-left:5px; }
  .step-info__item:hover{
    background:rgba(255, 255, 255, 0.2); }
  .step-info__item:after{
    content:'';
    display:inline-block;
    width:14px;
    height:14px;
    margin-left:15px;
    background:transparent url("../img/icon/edit.svg") 50% 50% no-repeat; }

.date .datepicker-inline .datepicker{
  width:100%;
  max-width:360px;
  background:rgba(22, 22, 22, 0.8);
  -webkit-backdrop-filter:blur(14px);
          backdrop-filter:blur(14px);
  border-radius:7px;
  border:0; }

.date .datepicker--nav{
  padding:13px 20px;
  border-bottom:0;
  padding-bottom:0; }

.date .datepicker--nav-title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#fff; }

.date .datepicker--content{
  padding:20px;
  padding-top:0; }
  @media screen and (min-width: 768px){
    .date .datepicker--content{
      padding:20px 20px;
      padding-top:0; } }

.date .datepicker--days-names{
  margin-bottom:0;
  display:block; }

.date .datepicker--day-name{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  display:inline-flex;
  width:30px;
  height:30px;
  margin:0 5px;
  font-weight:500;
  color:#fff; }
  @media screen and (min-width: 768px){
    .date .datepicker--day-name{
      width:35px;
      height:35px; } }

.date .datepicker--cell-day{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  width:30px;
  height:30px;
  margin:0 5px;
  color:#fff; }
  @media screen and (min-width: 768px){
    .date .datepicker--cell-day{
      width:35px;
      height:35px; } }

.date .datepicker--cell.-disabled-{
  color:#fff;
  opacity:0.5; }

.date .datepicker--cell.-current-{
  color:#fff;
  border:1px solid rgba(255, 255, 255, 0.5);
  border-radius:50%; }

.date .datepicker--cell.-focus-{
  border-radius:50%;
  color:#292929; }

.date .datepicker--cell.-selected-{
  color:#292929;
  font-weight:500;
  background-color:#F7C449;
  border-radius:50%; }

.date .datepicker--cell.-selected-.-focus-{
  background-color:#F7C449; }

.date .datepicker--cell.-selected-, .date .datepicker--cell.-selected-.-current-{
  color:#292929;
  font-weight:500;
  background-color:#F7C449;
  border-radius:50%;
  border:0; }

.date-select{
  margin-left:-9px;
  width:calc(100% + 18px); }
  @media screen and (min-width: 768px){
    .date-select{
      display:flex; } }
  .date-select .date{
    margin-bottom:7px; }
    @media screen and (min-width: 768px){
      .date-select .date{
        margin-right:22px;
        margin-bottom:0; } }

.time-list{
  display:inline-flex;
  flex-direction:column;
  width:320px;
  padding:13px 18px;
  background:rgba(26, 26, 26, 0.81);
  -webkit-backdrop-filter:blur(10px);
          backdrop-filter:blur(10px);
  border-radius:7px;
  max-height:300px; }

.time-list__head{
  display:flex;
  justify-content:space-around;
  margin-bottom:24px; }

.time-list__anchor{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  padding:5px 10px 5px 10px;
  border-bottom:2px solid transparent;
  color:#fff;
  transition:all 0.1s linear; }
  .time-list__anchor.is-active{
    border-bottom:2px solid #F7C449; }

.time-list__body{
  overflow:hidden; }

.time-list__list{
  display:flex;
  flex-direction:column;
  overflow:auto;
  height:220px;
  width:100%; }
  .time-list__list::-webkit-scrollbar{
    width:0px; }
  .time-list__list::-moz-scrollbar{
    width:0px; }

.time-list__item{
  width:100%;
  position:relative; }

.time-list__name{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:18px;
  line-height:25px;
  display:inline-block;
  width:100%;
  margin-bottom:6px;
  padding:6px 10px;
  color:#fff;
  background:rgba(255, 255, 255, 0.1);
  border-radius:8px;
  padding-right:30px;
  cursor:pointer;
  transition:all 0.1s linear; }
  .time-list__name:hover{
    background:#F7C449;
    color:#000; }

.time-list__radio{
  position:absolute;
  width:1px;
  height:1px;
  margin:-1px;
  clip:rect(0 0 0 0);
  opacity:0; }

.time-list__radio:checked + .time-list__name{
  position:relative;
  color:#000;
  background:#F7C449; }
  .time-list__radio:checked + .time-list__name:after{
    content:'';
    position:absolute;
    right:10px;
    top:calc(50% - 5px);
    display:inline-block;
    width:13px;
    height:10px;
    background:transparent url(../img/icon/check.svg) 50% 50% no-repeat; }

.title-404{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:137px;
  line-height:147px;
  letter-spacing:-0.038em;
  color:#fff; }

.title-404__zero{
  color:#F7C449; }

@media screen and (min-width: 480px){
  .title-404{
    font-size:171px;
    line-height:185px; } }

@media screen and (min-width: 768px){
  .title-404{
    font-size:232px;
    line-height:251px; } }

.article{
  background-color:rgba(96, 96, 96, 0.3);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:10px;
  padding:16px;
  padding-bottom:40px;
  transition:all 0.3s; }
  .article_type_sale .article__type{
    border-color:#F7C449;
    background-color:#F7C449;
    color:#333; }

.article__header{
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-bottom:25px; }

.article__type{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  padding-left:9px;
  padding-right:9px;
  border:1px solid rgba(255, 255, 255, 0.2);
  border-radius:4px;
  color:rgba(255, 255, 255, 0.7); }

.article__date{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#fff; }

.article__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  color:#fff;
  margin-bottom:20px; }

.article__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#BDBDBD; }

.input-field{
  position:relative;
  display:block; }

.input-field__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:18px;
  display:inline-block;
  margin-bottom:5px; }

.input{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  width:100%;
  height:48px;
  transition:all 0.1s linear;
  padding:11px 16px;
  border:1px solid transparent;
  color:#fff;
  background:rgba(255, 255, 255, 0.25);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:7px; }
  @media screen and (min-width: 768px){
    .input{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:16px;
      line-height:24px;
      background:rgba(255, 255, 255, 0.15); } }
  .input:focus{
    border:1px solid #F7C449;
    outline:0; }
  .input.is-disabled{
    opacity:0.4;
    cursor:not-allowed; }
  .input_textarea{
    height:88px;
    resize:vertical; }

.input.error{
  border-color:#F13A30 !important; }

@media screen and (max-width: 767px){
  .input_sz_lg{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; } }

.checkbox{
  line-height:0; }

.checkbox__input{
  position:absolute;
  width:1px;
  height:1px;
  margin:-1px;
  clip:rect(0 0 0 0);
  opacity:0; }
  .checkbox__input:checked + .checkbox__label:before{
    border-color:#F7C449; }
  .checkbox__input:checked + .checkbox__label:after{
    opacity:1;
    background-image:url("../img/icon/check-yellow.svg"); }
  .checkbox__input:focus + .checkbox__label:before{
    border-color:#F7C449; }

.checkbox__label{
  display:inline-flex;
  flex-direction:column;
  position:relative;
  padding-left:44px;
  cursor:pointer; }
  .checkbox__label:before, .checkbox__label:after{
    content:'';
    top:50%;
    position:absolute;
    transform:translateY(-50%);
    transition:all 0.1s linear; }
  .checkbox__label:before{
    left:0;
    width:17px;
    height:17px;
    border:1.7px solid #FFFFFF;
    background:rgba(22, 22, 22, 0.7);
    -webkit-backdrop-filter:blur(4px);
            backdrop-filter:blur(4px);
    border-radius:1.7px; }
  .checkbox__label:after{
    top:calc(50% - 1px);
    left:2px;
    width:17px;
    height:14px;
    background-repeat:no-repeat;
    background-position:center;
    opacity:0; }

.checkbox__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  line-height:1; }
  @media screen and (min-width: 768px){
    .checkbox__title{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:16px;
      line-height:24px;
      line-height:1; } }

.checkbox__subtitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  margin-top:6px;
  font-size:10px;
  line-height:1;
  color:#F7C449; }
  @media screen and (min-width: 768px){
    .checkbox__subtitle{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:12px;
      line-height:24px;
      line-height:1; } }

@media screen and (min-width: 768px){
  .service-list{
    display:flex;
    flex-wrap:wrap;
    margin:-6px -8px; } }

.service-list__item{
  margin-bottom:8px;
  padding:5px 11px;
  background:rgba(22, 22, 22, 0.8);
  -webkit-backdrop-filter:blur(14px);
          backdrop-filter:blur(14px);
  border-radius:7px; }
  @media screen and (min-width: 768px){
    .service-list__item{
      width:calc(50% - 16px);
      margin:6px 8px;
      padding:12px 26px;
      background:rgba(22, 22, 22, 0.7);
      -webkit-backdrop-filter:blur(4px);
              backdrop-filter:blur(4px);
      border-radius:20px; } }
  @media screen and (min-width: 1200px){
    .service-list__item{
      width:calc(33% - 16px); } }

.agree{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  line-height:16px;
  color:#B4B4B4; }
  .agree a{
    color:#fff; }

.form__item + .form__item{
  margin-top:8px; }
  @media screen and (min-width: 768px){
    .form__item + .form__item{
      margin-top:12px; } }

.form__footer{
  margin-top:12px; }
  @media screen and (min-width: 768px){
    .form__footer{
      margin-top:15px; } }

.form_narrow{
  max-width:420px; }

.recomendations-list{
  background-color:rgba(96, 96, 96, 0.3);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:10px;
  padding:18px; }

.recomendations-list__box{
  display:flex;
  flex-wrap:wrap;
  margin:-20px; }

.recomendations-list__item{
  position:relative;
  margin:20px;
  width:calc(100% - 40px);
  padding-left:22px; }
  .recomendations-list__item:before{
    content:"";
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain; }
  .recomendations-list__item:before{
    top:15px;
    width:8px;
    height:8px;
    border-radius:50%;
    background-color:#F7C449; }

.recomendations-list__item-title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#fff;
  display:block; }

.recomendations-list__item-subtitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  color:#F7C449; }

@media screen and (min-width: 480px){
  .recomendations-list{
    padding:35px 20px; }
  .recomendations-list__box{
    margin:-25px; }
  .recomendations-list__item{
    margin:25px; } }

@media screen and (min-width: 600px){
  .recomendations-list__item{
    width:calc(50% - 50px); } }

@media screen and (min-width: 768px){
  .recomendations-list__item{
    width:calc(33.33% - 50px); } }

@media screen and (min-width: 960px){
  .recomendations-list{
    padding-left:30px;
    padding-right:30px; } }

.program-event{
  background-color:rgba(96, 96, 96, 0.3);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:10px;
  padding:30px 20px; }

.program-event__date{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  color:#F7C449;
  margin-bottom:10px; }

.program-event__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#fff;
  margin-bottom:20px; }

.program-event__buttons{
  overflow:hidden; }
  .program-event__buttons-row{
    display:flex;
    flex-wrap:wrap;
    margin:-5px -5px; }
  .program-event__buttons-item{
    width:calc(50% - 5px*2);
    margin:5px 5px; }

.tags-list__grid{
  overflow:hidden; }
  .tags-list__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-3px -3px; }
  .tags-list__grid-item{
    width:auto;
    margin:3px 3px; }

.tags-list__grid-item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  padding:5px;
  background-color:#515151;
  border-radius:7px;
  line-height:1;
  color:#fff; }

.slick-dots-1 .slick-dots{
  display:flex;
  flex-wrap:wrap;
  margin:-8px; }

.slick-dots-1 .slick-dots li{
  margin:8px; }
  .slick-dots-1 .slick-dots li.slick-active button{
    background-color:#F7C449; }

.slick-dots-1 .slick-dots button{
  display:block;
  width:6px;
  height:6px;
  border-radius:50%;
  font-size:0;
  background-color:#fff;
  cursor:pointer; }

.contact-slider{
  position:relative; }

.contact-slider__init,
.contact-slider__init *{
  height:100%; }

.contact-slider__image{
  display:block;
  width:100%;
  height:100%;
  -o-object-fit:cover;
     object-fit:cover; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .contact-slider__image{
      height:auto; } }

.contact-slider__dots{
  position:absolute;
  z-index:1;
  left:20px;
  bottom:20px; }

.contact-info{
  background-color:rgba(96, 96, 96, 0.3);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:10px;
  overflow:hidden; }

.contact-info__picture{
  position:relative;
  width:100%;
  height:220px; }

.contact-info__slider{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0; }

.contact-info__slider .slick-list,
.contact-info__slider .slick-track,
.contact-info__slider .slick-slide{
  height:100%; }

.contact-info__content{
  padding:30px 20px; }

.contact-info__item{
  margin-bottom:25px; }
  .contact-info__item:last-child{
    margin-bottom:0; }

.contact-info__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  color:#fff;
  margin-bottom:10px; }
  .contact-info__title:last-child{
    margin-bottom:0; }

.contact-info__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#fff; }

.contact-info__links-item,
.contact-info__text li{
  margin-bottom:5px; }
  .contact-info__links-item:last-child,
  .contact-info__text li:last-child{
    margin-bottom:0; }

.contact-info__link{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  transition:all 0.1s linear;
  color:#fff; }
  .contact-info__link:hover, .contact-info__link:focus{
    color:#F7C449; }
  .contact-info__link[class*="_icon"]{
    position:relative;
    padding-left:24px; }
    .contact-info__link[class*="_icon"]:before{
      content:"";
      position:absolute;
      left:0;
      top:50%;
      transform:translateY(-50%);
      background-repeat:no-repeat;
      background-position:center;
      background-size:contain; }
    .contact-info__link[class*="_icon"]:before{
      width:16px;
      height:16px; }
  .contact-info__link_icon_phone:before{
    background-image:url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M14.7162 10.5022C13.7366 10.5022 12.7747 10.349 11.8633 10.0478C11.4167 9.89542 10.8676 10.0352 10.595 10.3151L8.796 11.6732C6.70963 10.5595 5.42446 9.27475 4.32596 7.20404L5.64408 5.45188C5.98654 5.10988 6.10938 4.61029 5.96221 4.14154C5.65971 3.22529 5.50604 2.26392 5.50604 1.28392C5.50608 0.575958 4.93013 0 4.22221 0H1.28387C0.575958 0 0 0.575958 0 1.28387C0 9.39846 6.60158 16 14.7162 16C15.4241 16 16 15.424 16 14.7161V11.786C16 11.0781 15.424 10.5022 14.7162 10.5022Z' fill='%23F7C449'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); }
  .contact-info__link_icon_mail:before{
    background-image:url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.333768 2.97362C2.52971 4.83334 6.38289 8.10516 7.51539 9.12531C7.66742 9.263 7.83049 9.333 7.99977 9.333C8.16871 9.333 8.33149 9.26366 8.48318 9.12663C9.61664 8.10547 13.4698 4.83334 15.6658 2.97362C15.8025 2.85806 15.8234 2.65494 15.7127 2.51366C15.4568 2.18719 15.0753 2 14.6664 2H1.33311C0.924268 2 0.542737 2.18719 0.286893 2.51369C0.176205 2.65494 0.197049 2.85806 0.333768 2.97362Z' fill='%23F7C449'/%3E%3Cpath d='M15.8067 3.98127C15.6885 3.92627 15.5495 3.94546 15.4512 4.02946C13.0159 6.0939 9.90788 8.74008 8.93 9.62124C8.38116 10.1167 7.61944 10.1167 7.06931 9.62058C6.027 8.68146 2.53675 5.71433 0.548813 4.02943C0.449844 3.94543 0.310531 3.9269 0.193344 3.98124C0.0755312 4.03596 0 4.1538 0 4.28368V12.6665C0 13.4019 0.597969 13.9998 1.33334 13.9998H14.6667C15.402 13.9998 16 13.4019 16 12.6665V4.28368C16 4.1538 15.9245 4.03565 15.8067 3.98127Z' fill='%23F7C449'/%3E%3C/svg%3E%0A"); }

.contact-info__buttons{
  overflow:hidden; }
  .contact-info__buttons-row{
    display:flex;
    flex-wrap:wrap;
    margin:-8px -8px; }
  .contact-info__buttons-item{
    width:calc(50% - 8px*2);
    margin:8px 8px; }

.contact-info__buttons-item{
  padding-top:9px;
  padding-bottom:9px; }

@media screen and (max-width: 479px){
  .contact-info__content{
    padding-left:10px;
    padding-right:10px; } }

@media screen and (min-width: 600px){
  .contact-info__picture{
    height:310px; }
  .contact-info__content{
    padding:50px 35px; }
  .contact-info__item{
    margin-bottom:35px; }
  .contact-info__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px; }
  .contact-info__links-item{
    margin-bottom:10px; }
  .contact-info__link{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; }
  .contact-info__buttons-item{
    width:auto;
    min-width:160px; } }

@media screen and (min-width: 768px){
  .contact-info__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; }
  .contact-info__link{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:18px; } }

@media screen and (min-width: 960px){
  .contact-info{
    display:flex;
    flex-direction:row-reverse; }
  .contact-info__picture{
    height:auto;
    width:calc(50% - 28px); }
  .contact-info__content{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:calc(50% + 28px);
    padding-left:45px;
    padding-right:45px; }
    .contact-info__content > *{
      max-width:100%; } }

.contact-info-2{
  background-color:rgba(96, 96, 96, 0.3);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:10px;
  padding:20px 15px; }
  .contact-info-2[class*='_icon']:before{
    content:'';
    flex-shrink:0;
    display:block;
    width:40px;
    height:40px;
    border-radius:50%;
    border:1px solid #F7C449;
    background-repeat:no-repeat;
    background-position:center;
    background-size:12px auto;
    margin-bottom:20px; }
  .contact-info-2_icon_phone:before{
    background-image:url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M11.0371 7.87663C10.3024 7.87663 9.58106 7.76172 8.89747 7.53581C8.5625 7.42156 8.15072 7.52638 7.94628 7.73634L6.597 8.75491C5.03222 7.91963 4.06834 6.95606 3.24447 5.40303L4.23306 4.08891C4.48991 3.83241 4.58203 3.45772 4.47166 3.10616C4.24478 2.41897 4.12953 1.69794 4.12953 0.962937C4.12956 0.431969 3.69759 0 3.16666 0H0.962906C0.431969 0 0 0.431969 0 0.962906C0 7.04884 4.95119 12 11.0371 12C11.5681 12 12 11.568 12 11.0371V8.8395C12 8.30859 11.568 7.87663 11.0371 7.87663Z' fill='%23F7C449'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath d='M0 0H12V12H0V0Z' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); }
  .contact-info-2_icon_mail:before{
    background-image:url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.249593 2.23022C1.89655 3.62501 4.78644 6.07887 5.63581 6.84398C5.74984 6.94725 5.87213 6.99975 5.99909 6.99975C6.1258 6.99975 6.24788 6.94774 6.36165 6.84497C7.21175 6.0791 10.1016 3.62501 11.7486 2.23022C11.8512 2.14355 11.8668 1.9912 11.7838 1.88524C11.5919 1.64039 11.3057 1.5 10.9991 1.5H0.999101C0.692468 1.5 0.40632 1.64039 0.214437 1.88527C0.131422 1.9912 0.147054 2.14355 0.249593 2.23022Z' fill='%23F7C449'/%3E%3Cpath d='M11.855 2.98424C11.7664 2.94299 11.6621 2.95739 11.5884 3.02039C9.76195 4.56871 7.43091 6.55335 6.6975 7.21422C6.28587 7.5858 5.71458 7.5858 5.30198 7.21373C4.52025 6.50939 1.90256 4.28404 0.411609 3.02036C0.337383 2.95736 0.232898 2.94346 0.145008 2.98422C0.0566484 3.02526 0 3.11364 0 3.21105V9.49816C0 10.0497 0.448477 10.4982 1.00001 10.4982H11C11.5515 10.4982 12 10.0497 12 9.49816V3.21105C12 3.11364 11.9434 3.02503 11.855 2.98424Z' fill='%23F7C449'/%3E%3C/svg%3E%0A"); }

.contact-info-2__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  color:#BDBDBD;
  margin-bottom:5px; }

.contact-info-2__list-item{
  margin-bottom:5px; }
  .contact-info-2__list-item:last-child{
    margin-bottom:0; }

.contact-info-2__list-link{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  transition:all 0.1s linear;
  color:#fff; }
  .contact-info-2__list-link:hover, .contact-info-2__list-link:focus{
    color:#F7C449; }

@media screen and (min-width: 480px){
  .contact-info-2{
    padding:40px 20px;
    display:flex;
    align-items:flex-start; }
    .contact-info-2[class*='_icon']:before{
      width:62px;
      height:62px;
      background-size:22px auto;
      margin-right:38px;
      margin-bottom:0; }
  .contact-info-2__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:18px;
    margin-bottom:10px; }
  .contact-info-2__list-item{
    margin-bottom:10px; } }

@media screen and (min-width: 768px){
  .contact-info-2__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; }
  .contact-info-2__list-link{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; } }

@media screen and (min-width: 960px) and (max-width: 1199px){
  .contact-info-2[class*='_icon']:before{
    margin-right:20px; } }

.data-list{
  max-width:500px; }

.data-list__item{
  display:flex;
  align-items:center;
  margin-bottom:8px;
  padding:9px 8px;
  background:rgba(24, 24, 24, 0.7);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:4px; }

.data-list__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  display:inline-block;
  line-height:18px;
  color:#fff; }

.data-list__img{
  width:30px;
  height:30px;
  margin-right:8px; }

.service-card{
  background-color:rgba(96, 96, 96, 0.3);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:10px;
  overflow:hidden; }

.service-card__image-wrap{
  width:100%;
  height:170px; }

.service-card__image{
  display:block;
  width:100%;
  height:100%;
  -o-object-fit:cover;
     object-fit:cover; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .service-card__image{
      height:auto; } }

.service-card__content{
  padding:15px; }

.service-card__title-row{
  margin-bottom:15px; }

.service-card__subtitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:24px;
  color:#F7C449;
  margin-bottom:10px; }

.service-card__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:18px;
  line-height:25px;
  color:#fff;
  margin-bottom:15px; }

.service-card__price{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:22px;
  color:#F7C449;
  white-space:nowrap; }

.service-card__buttons{
  overflow:hidden; }
  .service-card__buttons-row{
    display:flex;
    flex-wrap:wrap;
    margin:-4px -4px; }
  .service-card__buttons-item{
    width:calc(50% - 4px*2);
    margin:4px 4px; }

@media screen and (min-width: 600px){
  .service-card,
  .service-card__content{
    display:flex;
    flex-direction:column; }
    .service-card > *,
    .service-card__content > *{
      max-width:100%; }
  .service-card__content{
    flex-grow:1; }
  .service-card__buttons{
    margin-top:auto; } }

@media screen and (min-width: 768px){
  .service-card__title-row{
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    margin-bottom:20px; }
  .service-card__subtitle{
    width:100%; }
  .service-card__title{
    margin-right:5px;
    margin-bottom:0; } }

.price-category__image-wrap{
  position:relative;
  width:100%;
  padding-bottom:80%;
  background-color:rgba(79, 79, 79, 0.6);
  border-radius:4px;
  margin-bottom:5px; }

.price-category__image{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  max-width:100%; }

.price-category__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  color:#F7C449;
  margin-bottom:5px; }

.price-category__price{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:22px;
  color:#fff; }

.history-item__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px;
  position:relative;
  padding-top:26px;
  color:#fff;
  margin-bottom:10px; }
  .history-item__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:56px;
    height:6px;
    background-color:#F7C449; }

.history-item__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#fff; }

@media screen and (min-width: 600px){
  .history-item__title{
    margin-bottom:20px; }
  .history-item__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 768px){
  .history-item__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px;
    margin-bottom:30px; }
  .history-item__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; } }

.ordered-list{
  counter-reset:ordered-list-counter; }

.ordered-list__item{
  position:relative;
  list-style:none;
  padding-left:35px; }
  .ordered-list__item:before{
    content:"";
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain; }
  .ordered-list__item:before{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px;
    content:counter(ordered-list-counter) ".";
    counter-increment:ordered-list-counter;
    top:9px;
    color:#F7C449; }

.search-field{
  position:relative;
  height:52px; }

.search-field__field,
.search-field__input{
  height:100%; }

.search-field__input{
  padding-right:52px; }

.search-field__submit{
  position:absolute;
  width:24px;
  height:24px;
  right:14px;
  top:50%;
  transform:translateY(-50%);
  background-image:url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.502 14.0012H14.708L14.432 13.7272C15.407 12.5902 16 11.1162 16 9.50119C16 5.91119 13.09 3.00119 9.5 3.00119C5.91 3.00119 3 5.91119 3 9.50119C3 13.0912 5.91 16.0012 9.5 16.0012C11.115 16.0012 12.588 15.4092 13.725 14.4352L14.001 14.7092V15.5012L18.999 20.4922L20.49 19.0012L15.502 14.0012ZM9.5 14.0012C7.014 14.0012 5 11.9872 5 9.50119C5 7.01619 7.014 5.00119 9.5 5.00119C11.985 5.00119 14 7.01619 14 9.50119C14 11.9872 11.985 14.0012 9.5 14.0012Z' fill='%23BDBDBD'/%3E%3C/svg%3E%0A");
  background-repeat:no-repeat;
  background-position:center;
  background-size:contain;
  cursor:pointer; }

.search-field__list{
  position:absolute;
  z-index:1;
  width:100%;
  top:100%;
  transform:translateY(4px);
  background-color:#333333;
  border-radius:4px;
  box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
  padding:20px; }
  .search-field__list:not(.show){
    display:none; }

.search-field__list-item{
  margin-bottom:5px; }
  .search-field__list-item:last-child{
    margin-bottom:0; }

.search-field__list-link{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#fff;
  transition:all 0.1s linear; }
  .search-field__list-link:hover, .search-field__list-link:focus{
    color:#828282; }

@media screen and (min-width: 480px){
  .search-field__input{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; } }

.search-result{
  background-color:rgba(96, 96, 96, 0.3);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:10px;
  padding:16px;
  display:flex;
  align-items:center;
  transition:all 0.3s; }
  .search-result[href]:hover, .search-result[href]:focus{
    background-color:rgba(26, 26, 26, 0.4); }

.search-result__image-wrap{
  flex-shrink:0;
  width:82px;
  height:82px;
  padding:10px;
  background-color:rgba(79, 79, 79, 0.6);
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:4px;
  margin-right:16px; }

.search-result__image{
  max-width:100%; }

.search-result__model{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#F7C449; }

.search-result__category{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  color:#fff; }

@media screen and (min-width: 480px){
  .search-result__image-wrap{
    width:124px;
    height:124px;
    padding:16px;
    margin-right:20px; } }

@media screen and (min-width: 600px){
  .search-result__model{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; }
  .search-result__category{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:28px;
    line-height:35px; } }

.order-form{
  background-color:rgba(96, 96, 96, 0.3);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  border-radius:10px;
  padding:35px 20px; }

.order-form__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  color:#fff;
  margin-bottom:30px; }

.order-form__inner{
  width:550px;
  max-width:100%; }

.order-form__fields{
  overflow:hidden; }
  .order-form__fields-row{
    display:flex;
    flex-wrap:wrap;
    margin:-8px -8px; }
  .order-form__fields-item{
    width:calc(100% - 8px*2);
    margin:8px 8px; }

.order-form__submit-btn{
  width:100%;
  margin-top:35px; }

@media screen and (min-width: 480px){
  .order-form{
    padding-left:25px;
    padding-right:25px; }
  .order-form__fields-item_wd_half{
    width:calc(50% - 8px*2); }
  .order-form__submit-btn{
    width:auto;
    min-width:280px;
    margin-top:55px; } }

@media screen and (min-width: 600px){
  .order-form{
    padding:45px 35px; } }

@media screen and (min-width: 1200px){
  .order-form{
    padding-left:45px;
    padding-right:45px; } }

.care-service{
  padding:15px;
  background-color:rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter:blur(9px);
          backdrop-filter:blur(9px);
  border-top-left-radius:4px;
  border-bottom-left-radius:4px;
  border-top-right-radius:10px;
  border-bottom-right-radius:10px; }

.care-service__image-wrap{
  width:64px;
  height:64px;
  border-radius:4px;
  overflow:hidden;
  margin-bottom:20px; }
  .care-service__image-wrap > img{
    display:block;
    width:100%;
    height:100%;
    -o-object-fit:cover;
       object-fit:cover; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .care-service__image-wrap{
      overflow:hidden;
      display:flex;
      justify-content:center;
      align-items:center; }
      .care-service__image-wrap > img{
        height:auto; } }

.care-service__content{
  position:relative;
  padding-top:26px; }
  .care-service__content:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:64px;
    height:6px;
    background-color:#fff; }

.care-service__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  width:850px;
  max-width:100%; }

@media screen and (min-width: 480px){
  .care-service__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; } }

@media screen and (min-width: 600px){
  .care-service__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 768px){
  .care-service{
    display:flex;
    padding:0; }
  .care-service__image-wrap{
    width:123px;
    height:123px;
    margin-bottom:0;
    flex-shrink:0;
    margin-right:40px; }
  .care-service__content{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:calc(100% - 123px - 40px);
    height:100%;
    padding-right:16px;
    padding-bottom:16px; }
    .care-service__content > *{
      max-width:100%; }
    .care-service__content:before{
      top:16px; } }

.care-step{
  padding:20px;
  background-image:linear-gradient(145.14deg, rgba(255, 194, 48, 0.89) 36.78%, rgba(253, 181, 74, 0.89) 77.9%);
  border-radius:4px;
  color:#333333; }

.care-step__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  margin-bottom:20px; }

.care-step__grid{
  overflow:hidden; }
  .care-step__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-10px -0px; }
  .care-step__grid-item{
    width:calc(100% - 0px*2);
    margin:10px 0px; }

@media screen and (max-width: 479px){
  .care-step__title:after{
    display:none; } }

@media screen and (min-width: 480px){
  .care-step__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px;
    position:relative;
    padding-right:60px; }
    .care-step__title:after{
      position:absolute;
      top:0;
      right:0;
      color:#fff; } }

@media screen and (min-width: 600px){
  .care-step__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:46px;
    line-height:59px;
    padding-right:80px;
    margin-bottom:40px; }
    .care-step__title:after{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:normal;
      font-size:52px;
      line-height:56px; } }

@media screen and (min-width: 768px){
  .care-step{
    padding-left:30px;
    padding-right:30px;
    padding-bottom:60px; }
  .care-step__title{
    margin-bottom:60px; } }

.main-banner{
  position:relative;
  width:100%;
  background-color:#000;
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;
  padding-top:100px;
  padding-bottom:100px;
  color:#fff; }
  .main-banner_full-screen{
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center; }
  .main-banner_theme_white{
    background-color:#fff;
    color:#000; }
    .main-banner_theme_white:before{
      display:none; }
  .main-banner:before{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    content:'';
    background-color:rgba(0, 0, 0, 0.2); }
  .main-banner_bgi_404{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/404/404-768.jpg"); }
  .main-banner_bgi_addresses{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/addresses/addresses-768.jpg"); }
  .main-banner_bgi_contacts{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/contacts/contacts-768.jpg"); }
  .main-banner_bgi_corporate{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/corporate/corporate-768.jpg"); }
  .main-banner_bgi_loyalty{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/loyalty/loyalty-768.jpg"); }
  .main-banner_bgi_about{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/about/about-768.jpg"); }
  .main-banner_bgi_application{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/application/application-768.jpg"); }
  .main-banner_bgi_recomendations{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/recomendations/recomendations-768.jpg"); }
  .main-banner_bgi_search{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/search/search-768.jpg");
    background-attachment:fixed; }
  .main-banner_bgi_services{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/services/services-768.jpg"); }
  .main-banner_bgi_selection{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/selection/selection-768.jpg"); }
  .main-banner_bgi_recomendations-2{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/recomendations-2/recomendations-2-768.jpg"); }
  .main-banner_bgi_service-overview{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/service-overview/service-overview-768.jpg"); }

.main-banner_bgi_services:after, .main-banner_bgi_addresses:after, .main-banner_bgi_loyalty:after, .main-banner_bgi_contacts:after{
  content:'';
  position:absolute;
  display:inline-block;
  width:100%;
  height:180px;
  top:100%;
  background:linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 225.19%); }

.main-banner__container{
  position:relative; }

.main-banner__content{
  display:flex;
  flex-direction:column;
  align-items:flex-start; }
  .main-banner__content > *{
    max-width:100%; }

.main-banner__subtitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#F7C449;
  margin-bottom:10px; }

.main-banner__title,
.main-banner__title-mark{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px; }

.main-banner__title{
  margin-bottom:15px;
  width:950px;
  max-width:100%; }

.main-banner__title-mark{
  color:#F7C449; }

.main-banner__title-2{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  margin-top:25px; }

.main-banner__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px; }

.main-banner__text p{
  margin-bottom:20px; }
  .main-banner__text p:last-child{
    margin-bottom:0; }

.main-banner__text-2{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:18px;
  line-height:25px;
  margin-top:150px;
  width:750px;
  max-width:100%; }

.main-banner__buttons,
.main-banner__button{
  margin-top:25px; }

.main-banner__buttons-box{
  display:flex;
  flex-wrap:wrap;
  margin:-5px; }

.main-banner__buttons-item{
  margin:5px;
  min-width:220px; }

.main-banner__button{
  min-width:160px; }

@media screen and (min-width: 480px){
  .main-banner__title-2{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:18px; }
  .main-banner__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px;
    width:650px;
    max-width:100%; } }

@media screen and (min-width: 600px){
  .main-banner__title,
  .main-banner__title-mark{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; }
  .main-banner__title-2{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; } }

@media screen and (min-width: 768px){
  .main-banner_sz_lg{
    padding-top:250px;
    padding-bottom:200px; }
  .main-banner_bgi_404{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/404/404-1200.jpg"); }
  .main-banner_bgi_addresses{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/addresses/addresses-1200.jpg"); }
  .main-banner_bgi_contacts{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/contacts/contacts-1200.jpg"); }
  .main-banner_bgi_corporate{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/corporate/corporate-1200.jpg"); }
  .main-banner_bgi_loyalty{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/loyalty/loyalty-1200.jpg"); }
  .main-banner_bgi_about{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/about/about-1200.jpg"); }
  .main-banner_bgi_application{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/application/application-1200.jpg"); }
  .main-banner_bgi_recomendations{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/recomendations/recomendations-1200.jpg"); }
  .main-banner_bgi_search{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/search/search-1200.jpg"); }
  .main-banner_bgi_services{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/services/services-1200.jpg"); }
  .main-banner_bgi_selection{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/selection/selection-1200.jpg"); }
  .main-banner_bgi_recomendations-2{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/recomendations-2/recomendations-2-1200.jpg"); }
  .main-banner_bgi_service-overview{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/service-overview/service-overview-1200.jpg"); }
  .main-banner__subtitle{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; }
  .main-banner__title,
  .main-banner__title-mark{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    margin-bottom:30px; }
  .main-banner__title-2{
    margin-top:50px; }
  .main-banner__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; }
  .main-banner__text-2{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px; }
  .main-banner__button{
    min-width:260px; } }

@media screen and (min-width: 1200px){
  .main-banner_bgi_404{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/404/404-1900.jpg"); }
  .main-banner_bgi_addresses{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/addresses/addresses-1900.jpg"); }
  .main-banner_bgi_contacts{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/contacts/contacts-1900.jpg"); }
  .main-banner_bgi_corporate{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/corporate/corporate-1900.jpg"); }
  .main-banner_bgi_loyalty{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/loyalty/loyalty-1900.jpg"); }
  .main-banner_bgi_about{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/about/about-1900.jpg"); }
  .main-banner_bgi_application{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/application/application-1900.jpg"); }
  .main-banner_bgi_recomendations{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/recomendations/recomendations-1900.jpg"); }
  .main-banner_bgi_search{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/search/search-1900.jpg"); }
  .main-banner_bgi_services{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/services/services-1900.jpg"); }
  .main-banner_bgi_selection{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/selection/selection-1900.jpg"); }
  .main-banner_bgi_recomendations-2{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/recomendations-2/recomendations-2-1900.jpg"); }
  .main-banner_bgi_service-overview{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/service-overview/service-overview-1900.jpg"); } }

@media screen and (min-width: 1600px){
  .main-banner__title-2{
    margin-top:100px; } }

@media screen and (min-width: 1921px){
  .main-banner_bgi_404{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/404/404-2600.jpg"); }
  .main-banner_bgi_addresses{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/addresses/addresses-2600.jpg"); }
  .main-banner_bgi_contacts{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/contacts/contacts-2600.jpg"); }
  .main-banner_bgi_corporate{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/corporate/corporate-2600.jpg"); }
  .main-banner_bgi_loyalty{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/loyalty/loyalty-2600.jpg"); }
  .main-banner_bgi_about{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/about/about-2600.jpg"); }
  .main-banner_bgi_application{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/application/application-2600.jpg"); }
  .main-banner_bgi_recomendations{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/recomendations/recomendations-2600.jpg"); }
  .main-banner_bgi_search{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/search/search-2600.jpg"); }
  .main-banner_bgi_services{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/services/services-2600.jpg"); }
  .main-banner_bgi_selection{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/selection/selection-2600.jpg"); }
  .main-banner_bgi_recomendations-2{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/recomendations-2/recomendations-2-2600.jpg"); }
  .main-banner_bgi_service-overview{
    background-image:linear-gradient(180deg, rgba(0, 0, 0, 0) 34.29%, rgba(0, 0, 0, 0.92) 100%), url("../img/components/main-banner/service-overview/service-overview-2600.jpg"); } }

.main-banner-about{
  overflow:hidden;
  padding-bottom:50px; }

.main-banner-about__title{
  margin-bottom:30px; }

.main-banner-about__principles-grid,
.main-banner-about__components-grid{
  overflow:hidden; }
  .main-banner-about__principles-grid-row,
  .main-banner-about__components-grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-10px -0px; }
  .main-banner-about__principles-grid-item,
  .main-banner-about__components-grid-item{
    width:calc(100% - 0px*2);
    margin:10px 0px; }

.main-banner-about__principles-grid{
  margin-bottom:80px; }

.main-banner-about__principles-grid-row{
  align-items:flex-start; }

.main-banner-about__principles-grid-item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  position:relative;
  color:#fff;
  padding-left:20px;
  min-height:38px;
  display:flex;
  align-items:center; }
  .main-banner-about__principles-grid-item:before{
    content:"";
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain; }
  .main-banner-about__principles-grid-item:before{
    top:0;
    transform:none;
    width:4px;
    height:38px;
    background-color:#F7C449; }

.main-banner-about__components-text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#fff; }

.main-banner-about__components-item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#fff;
  margin-bottom:20px; }
  .main-banner-about__components-item:last-child{
    margin-bottom:0; }

.main-banner-about__components-item-mark{
  color:#F7C449; }

@media screen and (min-width: 480px){
  .main-banner-about__principles-grid,
  .main-banner-about__components-grid{
    overflow:hidden; }
    .main-banner-about__principles-grid-row,
    .main-banner-about__components-grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-15px -15px; }
    .main-banner-about__principles-grid-item,
    .main-banner-about__components-grid-item{
      width:calc(50% - 15px*2);
      margin:15px 15px; } }

@media screen and (min-width: 600px){
  .main-banner-about__title{
    margin-bottom:40px; }
  .main-banner-about__principles-grid-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; }
    .main-banner-about__principles-grid-item:before{
      width:6px;
      height:50px; }
  .main-banner-about__components-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; }
  .main-banner-about__components-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px;
    margin-bottom:30px; } }

@media screen and (min-width: 768px){
  .main-banner-about__title{
    margin-bottom:60px; }
  .main-banner-about__principles-grid,
  .main-banner-about__components-grid{
    overflow:hidden; }
    .main-banner-about__principles-grid-row,
    .main-banner-about__components-grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-20px -40px; }
    .main-banner-about__principles-grid-item,
    .main-banner-about__components-grid-item{
      width:calc(50% - 40px*2);
      margin:20px 40px; }
  .main-banner-about__principles-grid{
    margin-bottom:120px; }
  .main-banner-about__principles-grid-item:before{
    top:50%;
    transform:translateY(-50%); } }

@media screen and (min-width: 960px){
  .main-banner-about__principles-grid,
  .main-banner-about__components-grid{
    overflow:hidden; }
    .main-banner-about__principles-grid-row,
    .main-banner-about__components-grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-25px -40px; }
    .main-banner-about__principles-grid-item,
    .main-banner-about__components-grid-item{
      width:calc(50% - 40px*2);
      margin:25px 40px; }
  .main-banner-about__principles-grid-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px; }
  .main-banner-about__components-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; } }

@media screen and (min-width: 1200px){
  .main-banner-about__content{
    width:900px;
    max-width:100%; }
  .main-banner-about__principles-grid,
  .main-banner-about__components-grid{
    overflow:hidden; }
    .main-banner-about__principles-grid-row,
    .main-banner-about__components-grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-25px -80px; }
    .main-banner-about__principles-grid-item,
    .main-banner-about__components-grid-item{
      width:calc(50% - 80px*2);
      margin:25px 80px; } }

@media screen and (min-width: 1921px){
  .main-banner-about__content{
    width:1200px; } }

.main-banner-application__grid-content{
  margin-bottom:60px; }

.main-banner-application__buttons{
  margin-top:20px; }

.main-banner-application__buttons-item{
  display:inline-block;
  margin-right:6px; }
  .main-banner-application__buttons-item:last-child{
    margin-right:0; }

.main-banner-application__image-wrap{
  width:237px;
  max-width:100%;
  height:505px; }
  .main-banner-application__image-wrap > img{
    display:block;
    width:100%;
    height:100%;
    -o-object-fit:contain;
       object-fit:contain; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .main-banner-application__image-wrap{
      display:flex;
      justify-content:center;
      align-items:center; }
      .main-banner-application__image-wrap > img{
        height:auto; } }

@media screen and (min-width: 480px){
  .main-banner-application__buttons-item{
    margin-right:25px; } }

@media screen and (min-width: 600px){
  .main-banner-application__image-wrap{
    width:300px;
    height:639px; } }

@media screen and (min-width: 768px){
  .main-banner-application__image-wrap{
    width:429px;
    height:914px; } }

@media screen and (min-width: 960px){
  .main-banner-application__grid-row{
    display:flex; }
  .main-banner-application__grid-content{
    width:460px;
    margin-right:25px;
    margin-top:80px;
    margin-bottom:0; }
  .main-banner-application__grid-image{
    width:calc(100% - 460px - 25px); }
  .main-banner-application__image-wrap{
    width:415px;
    height:886px;
    margin-left:auto;
    margin-right:auto; } }

@media screen and (min-width: 1200px){
  .main-banner-application__grid-row{
    justify-content:center; }
  .main-banner-application__grid-content{
    width:700px; }
  .main-banner-application__grid-image{
    width:400px; } }

.news-section__button{
  min-width:260px;
  margin-top:40px; }

@media screen and (min-width: 600px){
  .news-section__button{
    margin-top:50px; } }

.care-subsection__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:22px;
  color:#fff;
  margin-bottom:10px; }

.care-section__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px;
  color:#F7C449;
  margin-bottom:25px; }

.care-section__subtitle{
  color:#fff; }

.care-section__subsection{
  margin-bottom:50px; }
  .care-section__subsection:last-child{
    margin-bottom:0; }

@media screen and (min-width: 768px){
  .care-section__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px;
    margin-bottom:40px; } }

.services-section__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  color:#fff;
  margin-bottom:20px; }

@media screen and (min-width: 600px){
  .services-section__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px; } }

@media screen and (min-width: 768px){
  .services-section__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; } }

.want-know-item__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  color:#fff;
  margin-bottom:20px; }

.want-know-item__title-mark{
  color:#F7C449; }

.want-know-item__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#fff;
  margin-bottom:20px; }

.want-know-item__button{
  min-width:260px; }

@media screen and (min-width: 600px){
  .want-know-item__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px; }
  .want-know-item__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px;
    margin-bottom:30px; } }

@media screen and (min-width: 960px){
  .want-know-item__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; } }

.want-know{
  padding-top:60px;
  padding-bottom:60px;
  background-color:#000; }

.want-know__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px;
  color:#fff;
  margin-bottom:40px; }
  .want-know__title:before{
    content:'';
    display:block;
    width:94px;
    height:6px;
    background-color:#F7C449;
    margin-bottom:40px; }

.want-know__grid{
  overflow:hidden; }
  .want-know__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-30px -0; }
  .want-know__grid-item{
    width:calc(100% - 0*2);
    margin:30px 0; }

@media screen and (min-width: 600px){
  .want-know{
    padding-top:70px;
    padding-bottom:70px; }
  .want-know__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; } }

@media screen and (min-width: 768px){
  .want-know__grid{
    overflow:hidden; }
    .want-know__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -10px; }
    .want-know__grid-item{
      width:calc(50% - 10px*2);
      margin:30px 10px; } }

@media screen and (min-width: 960px){
  .want-know{
    padding-top:80px;
    padding-bottom:80px; }
  .want-know__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:53px;
    line-height:59px;
    margin-bottom:60px; }
  .want-know__grid{
    overflow:hidden; }
    .want-know__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -25px; }
    .want-know__grid-item{
      width:calc(50% - 25px*2);
      margin:30px 25px; } }

@media screen and (min-width: 1200px){
  .want-know__grid{
    overflow:hidden; }
    .want-know__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -25px; }
    .want-know__grid-item{
      width:calc(33.33% - 25px*2);
      margin:30px 25px; } }

.price-category-section__header{
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-bottom:20px; }

.price-category-section__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px;
  color:#fff; }

.price-category-section__link{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  position:relative;
  color:#F7C449;
  padding-right:24px; }
  .price-category-section__link:after{
    content:"";
    position:absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain; }
  .price-category-section__link:after{
    width:12px;
    height:8px;
    background-image:url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464467C8.97631 0.269205 8.65973 0.269205 8.46447 0.464467C8.2692 0.659729 8.2692 0.976311 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53553C8.65973 7.7308 8.97631 7.7308 9.17157 7.53553L12.3536 4.35355ZM-4.37114e-08 4.5L12 4.5L12 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z' fill='white'/%3E%3C/svg%3E%0A"); }

.price-category-section__grid{
  overflow:hidden; }
  .price-category-section__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-5px -15px; }
  .price-category-section__grid-item{
    width:calc(50% - 15px*2);
    margin:5px 15px; }

@media screen and (min-width: 480px){
  .price-category-section__link{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:18px;
    padding-right:36px; }
    .price-category-section__link:after{
      width:18px;
      height:16px;
      background-image:url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L11.3431 0.928932C10.9526 0.538408 10.3195 0.538408 9.92893 0.928932C9.53841 1.31946 9.53841 1.95262 9.92893 2.34315L15.5858 8L9.92893 13.6569C9.53841 14.0474 9.53841 14.6805 9.92893 15.0711C10.3195 15.4616 10.9526 15.4616 11.3431 15.0711L17.7071 8.70711ZM0 9H17V7H0V9Z' fill='white'/%3E%3C/svg%3E%0A"); } }

@media screen and (min-width: 600px){
  .price-category-section__grid{
    overflow:hidden; }
    .price-category-section__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-8px -8px; }
    .price-category-section__grid-item{
      width:calc(25% - 8px*2);
      margin:8px 8px; } }

.service-detail__section{
  margin-bottom:40px;
  padding-bottom:40px;
  border-bottom:1px solid #828282; }
  .service-detail__section:last-child{
    border-bottom:none;
    margin-bottom:0; }

.service-detail__image-wrap{
  width:100%;
  height:190px;
  border-radius:10px;
  overflow:hidden;
  margin-bottom:35px; }

.service-detail__image{
  display:block;
  width:100%;
  height:100%;
  -o-object-fit:cover;
     object-fit:cover; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .service-detail__image{
      height:auto; } }

.service-detail__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px;
  color:#fff;
  margin-bottom:20px; }

.service-detail__subtitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  color:#F7C449; }

.service-detail__submit-btn{
  width:100%;
  margin-top:30px; }

@media screen and (min-width: 480px){
  .service-detail__image-wrap{
    height:300px;
    margin-bottom:50px; } }

@media screen and (min-width: 600px){
  .service-detail__image-wrap{
    height:370px; }
  .service-detail__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; } }

.tape-section{
  overflow:hidden;
  transform:skewY(3.5deg);
  padding-top:80px;
  padding-bottom:80px;
  background-color:#F7C449;
  background-image:linear-gradient(107.62deg, #FFC230 36.78%, #FDB54A 77.9%);
  background-position:center; }
  .tape-section > *, .tape-section:before, .tape-section:after{
    transform:skewY(-3.5deg); }
  .tape-section_reverse{
    overflow:hidden;
    transform:skewY(-3.5deg); }
    .tape-section_reverse > *, .tape-section_reverse:before, .tape-section_reverse:after{
      transform:skewY(3.5deg); }

.tape-section__content{
  width:700px;
  max-width:100%; }

.tape-section__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px;
  position:relative;
  padding-top:26px;
  color:#000;
  margin-bottom:20px; }
  .tape-section__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#fff; }

.tape-section__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px; }

.tape-section__button{
  margin-top:35px; }

@media screen and (min-width: 480px){
  .tape-section__button{
    margin-top:45px; } }

@media screen and (min-width: 768px){
  .tape-section{
    padding-top:100px;
    padding-bottom:100px; }
  .tape-section__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    margin-bottom:35px; }
  .tape-section__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px; } }

@media screen and (min-width: 960px){
  .tape-section{
    overflow:hidden;
    transform:skewY(0deg);
    background:none;
    padding-top:0;
    padding-bottom:0;
    height:700px; }
    .tape-section > *, .tape-section:before, .tape-section:after{
      transform:skewY(0deg); }
    .tape-section_reverse .tape-section__container:before{
      transform:scale(-1, 1); }
    .tape-section_reverse .tape-section__content{
      max-width:calc(100% - 350px);
      margin-left:350px; }
  .tape-section__container{
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:center;
    height:100%; }
    .tape-section__container > *{
      max-width:100%; }
    .tape-section__container:before{
      content:'';
      position:absolute;
      left:-1000px;
      right:-1000px;
      top:0;
      bottom:0;
      background-color:transparent;
      background-image:url("data:image/svg+xml,%3Csvg width='2319' height='940' viewBox='0 0 2319 940' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1806 221.5L0 144.5V699.5L1806 776.5V221.5Z' fill='url(%23paint0_linear)'/%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M1950 30C1950 30 2279 438.776 2279 599.263C2279 676.371 2244.34 750.321 2182.64 804.845C2120.94 859.369 2037.26 890 1950 890C1862.74 890 1779.06 859.369 1717.36 804.845C1655.66 750.321 1621 676.371 1621 599.263C1621 438.776 1950 30 1950 30ZM1950 720.985C1968.9 721.036 1987.63 717.79 2005.11 711.433C2022.59 705.075 2038.48 695.732 2051.87 683.938C2065.26 672.144 2075.88 658.131 2083.12 642.703C2090.37 627.275 2094.1 610.734 2094.1 594.03C2094.1 523.865 1950.44 322.675 1950.44 322.675C1950.44 322.675 1806.78 524.447 1806.78 594.03C1806.78 627.633 1821.85 659.866 1848.7 683.664C1875.55 707.461 1911.97 720.882 1950 720.985Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='1581' y='0' width='738' height='940' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='10'/%3E%3CfeGaussianBlur stdDeviation='20'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'/%3E%3CfeBlend mode='multiply' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear' x1='1626.69' y1='173.565' x2='723.151' y2='993.752' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.40625' stop-color='%23FFC230'/%3E%3Cstop offset='1' stop-color='%23FDB54A'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      background-repeat:no-repeat;
      background-position:right 790px center;
      background-size:auto 100%; }
  .tape-section__content{
    position:relative; } }

@media screen and (min-width: 1200px){
  .tape-section{
    height:860px; }
    .tape-section_reverse .tape-section__content{
      max-width:calc(100% - 450px);
      margin-left:450px; } }

@media screen and (min-width: 1600px){
  .tape-section_reverse .tape-section__content{
    max-width:calc(100% - 350px);
    margin-left:350px; }
  .tape-section__container:before{
    background-position:right 880px center; } }

@media screen and (min-width: 1921px){
  .tape-section{
    height:940px; }
    .tape-section_reverse .tape-section__content{
      max-width:calc(100% - 550px);
      margin-left:550px; }
  .tape-section__container{
    position:relative;
    justify-content:center; }
    .tape-section__container:before{
      background-position:right 1000px center; } }

.history-past{
  padding-top:100px;
  padding-bottom:100px; }

.history-past__grid{
  overflow:hidden; }
  .history-past__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-15px -15px; }
  .history-past__grid-item{
    width:calc(100% - 15px*2);
    margin:15px 15px; }

@media screen and (min-width: 600px){
  .history-past__grid{
    overflow:hidden; }
    .history-past__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-20px -20px; }
    .history-past__grid-item{
      width:calc(100% - 20px*2);
      margin:20px 20px; } }

@media screen and (min-width: 960px){
  .history-past{
    padding-top:120px;
    padding-bottom:120px; }
  .history-past__grid{
    overflow:hidden; }
    .history-past__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-25px -25px; }
    .history-past__grid-item{
      width:calc(50% - 25px*2);
      margin:25px 25px; } }

@media screen and (min-width: 1200px){
  .history-past__grid{
    overflow:hidden; }
    .history-past__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-50px -50px; }
    .history-past__grid-item{
      width:calc(50% - 50px*2);
      margin:50px 50px; } }

@media screen and (min-width: 1921px){
  .history-past__grid{
    overflow:hidden; }
    .history-past__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-100px -50px; }
    .history-past__grid-item{
      width:calc(50% - 50px*2);
      margin:100px 50px; } }

.company-card{
  border-radius:10px;
  overflow:hidden;
  color:#000; }

.company-card__content{
  padding:60px 20px;
  background-image:linear-gradient(107.62deg, #FFC230 36.78%, #FDB54A 77.9%); }

.company-card__content.order-form{
  background-image:none;
  padding:35px 20px; }

.company-card__subtitle{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  margin-bottom:5px; }

.company-card__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px;
  margin-bottom:15px; }
  .company-card__title_line{
    position:relative;
    padding-top:26px; }
    .company-card__title_line:before{
      content:'';
      position:absolute;
      top:0;
      left:0;
      width:94px;
      height:6px;
      background-color:#fff; }

.company-card__title-mark{
  color:#fff; }

.company-card__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px; }

.company-card__image-wrap{
  width:100%;
  height:240px; }
  .company-card__image-wrap > img{
    display:block;
    width:100%;
    height:100%;
    -o-object-fit:cover;
       object-fit:cover; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .company-card__image-wrap{
      overflow:hidden;
      display:flex;
      justify-content:center;
      align-items:center; }
      .company-card__image-wrap > img{
        height:auto; } }

@media screen and (min-width: 480px){
  .company-card__content.order-form{
    padding-left:25px;
    padding-right:25px; }
  .company-card__image-wrap{
    height:370px; } }

@media screen and (min-width: 600px){
  .company-card__content.order-form{
    padding:45px 35px; }
  .company-card__image-wrap{
    height:460px; } }

@media screen and (min-width: 768px){
  .company-card__content{
    padding-left:30px;
    padding-right:30px; }
  .company-card__subtitle{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; }
  .company-card__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:53px;
    line-height:59px;
    margin-bottom:20px; }
  .company-card__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; }
    .company-card__text_fz_sm{
      font-family:Gotham Pro, sans-serif;
      font-style:normal;
      font-weight:400;
      font-size:20px;
      line-height:29px; }
  .company-card__image-wrap{
    height:590px; } }

@media screen and (max-width: 959px){
  .company-card__content{
    border-bottom-left-radius:0;
    border-bottom-right-radius:0; } }

@media screen and (min-width: 960px){
  .company-card{
    display:flex; }
    .company-card_reverse{
      flex-direction:row-reverse; }
  .company-card__content,
  .company-card__image-col{
    width:50%;
    flex-shrink:0;
    min-height:600px; }
  .company-card__content{
    display:flex;
    flex-direction:column;
    justify-content:center;
    border-top-right-radius:0;
    border-bottom-right-radius:0; }
    .company-card__content > *{
      max-width:100%; }
  .company-card__content.order-form{
    padding-right:50px; }
  .company-card__image-col{
    position:relative; }
  .company-card__image-wrap{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    height:auto; } }

@media screen and (min-width: 1200px){
  .company-card__content{
    padding-left:60px;
    padding-right:60px; }
  .company-card__content.order-form{
    padding-left:45px;
    padding-right:65px; }
  .company-card__title{
    margin-bottom:40px; } }

@media screen and (min-width: 1921px){
  .company-card__content{
    padding-left:100px;
    padding-right:100px; } }

.list-section{
  padding-top:80px;
  padding-bottom:80px;
  background-color:#fff;
  color:#292929; }
  .list-section_theme_dark{
    background:#000; }
  .list-section_theme_dark,
  .list-section_theme_dark .list-section__show-btn{
    color:#fff; }
  .list-section_icons_logo .list-section__grid-item:before{
    top:15px;
    width:17px;
    height:21px;
    background-color:transparent;
    background-image:url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 0C8.5 0 0 9.98174 0 13.9006C0 15.7835 0.895533 17.5892 2.48959 18.9206C4.08365 20.252 6.24566 21 8.5 21C10.7543 21 12.9163 20.252 14.5104 18.9206C16.1045 17.5892 17 15.7835 17 13.9006C17 9.98174 8.5 0 8.5 0ZM8.5 16.8729C8.01162 16.8741 7.52776 16.7949 7.07613 16.6396C6.6245 16.4844 6.21399 16.2562 5.86813 15.9683C5.52226 15.6803 5.24785 15.3381 5.06061 14.9614C4.87338 14.5846 4.777 14.1807 4.777 13.7728C4.777 12.0595 8.48867 7.14672 8.48867 7.14672C8.48867 7.14672 12.2003 12.0737 12.2003 13.7728C12.2003 14.5934 11.8108 15.3805 11.1172 15.9616C10.4236 16.5427 9.48243 16.8704 8.5 16.8729Z' fill='%23F7C449'/%3E%3C/svg%3E%0A"); }

.list-section__title,
.list-section__title-mark{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:28px;
  line-height:35px; }

.list-section__title{
  position:relative;
  padding-top:26px;
  margin-bottom:30px;
  width:950px;
  max-width:100%; }
  .list-section__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.list-section__title-mark{
  color:#F7C449; }

.list-section__grid{
  overflow:hidden; }
  .list-section__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-20px -20px; }
  .list-section__grid-item{
    width:calc(100% - 20px*2);
    margin:20px 20px; }

.list-section__grid_wide{
  width:100%; }
  .list-section__grid_wide .list-section__grid-item{
    width:100%;
    margin-left:0; }

.list-section__grid-item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  position:relative;
  padding-left:46px; }
  .list-section__grid-item:before{
    content:"";
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain; }
  .list-section__grid-item:before{
    top:15px;
    width:28px;
    height:4px;
    background-color:#F7C449; }

.list-section__item-title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:24px;
  margin-bottom:10px; }

.list-section__item-text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px; }

.list-section__show-btn{
  min-width:288px;
  margin-top:40px; }

@media screen and (max-width: 479px){
  .list-section__grid-item{
    padding-left:0; }
    .list-section__grid-item:before{
      display:block;
      position:static;
      transform:none;
      margin-bottom:10px; } }

@media screen and (min-width: 480px){
  .list-section{
    padding-top:100px;
    padding-bottom:100px; } }

@media screen and (max-width: 599px){
  .list-section[show-block]:not(.active) .list-section__grid-item{
    display:none; }
    .list-section[show-block]:not(.active) .list-section__grid-item:nth-child(1), .list-section[show-block]:not(.active) .list-section__grid-item:nth-child(2){
      display:block; }
  .list-section.active .list-section__show-btn{
    display:none; } }

@media screen and (min-width: 600px){
  .list-section{
    background-image:url("../img/components/list-section/background.svg");
    background-repeat:no-repeat;
    background-position:bottom right -282px;
    background-size:721px 977px; }
    .list-section_bgp_reverse{
      background-position:bottom left -282px; }
  .list-section__title,
  .list-section__title-mark{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; }
  .list-section__title{
    margin-bottom:50px; }
  .list-section__grid{
    overflow:hidden; }
    .list-section__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-20px -20px; }
    .list-section__grid-item{
      width:calc(50% - 20px*2);
      margin:20px 20px; }
  .list-section__show-btn{
    display:none; } }

@media screen and (min-width: 768px){
  .list-section{
    padding-top:120px;
    padding-bottom:120px;
    background-position:bottom -200px right -596px;
    background-size:948px 1283px; }
    .list-section_bgp_reverse{
      background-position:bottom -200px left -596px; }
  .list-section__title,
  .list-section__title-mark{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:53px;
    line-height:59px; }
  .list-section__item-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 960px){
  .list-section__title{
    margin-bottom:90px; }
  .list-section__grid{
    overflow:hidden; }
    .list-section__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -65px; }
    .list-section__grid-item{
      width:calc(50% - 65px*2);
      margin:30px 65px; } }

@media screen and (min-width: 1200px){
  .list-section{
    background-position:bottom -200px right -450px;
    background-size:969px 1311px; }
    .list-section_bgp_reverse{
      background-position:bottom -200px left -450px; }
  .list-section__grid{
    overflow:hidden; }
    .list-section__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -30px; }
    .list-section__grid-item{
      width:calc(33.33% - 30px*2);
      margin:30px 30px; } }

@media screen and (min-width: 1600px){
  .list-section{
    background-position:bottom -200px right -180px; }
    .list-section_bgp_reverse{
      background-position:bottom -200px left -180px; } }

@media screen and (min-width: 1921px){
  .list-section{
    background-position:bottom -200px right -95px; }
    .list-section_bgp_reverse{
      background-position:bottom -200px left -95px; }
  .list-section__grid{
    overflow:hidden; }
    .list-section__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -80px; }
    .list-section__grid-item{
      width:calc(33.33% - 80px*2);
      margin:30px 80px; } }

.list-section-brands{
  background-image:url("../img/common/common-bg-2.jpg");
  background-repeat:no-repeat;
  background-position:center top;
  background-size:cover;
  overflow:hidden; }

.list-section-brands__item-title{
  margin-bottom:10px; }

.list-section-brands__item-text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px; }

.list-section-brands__footer{
  position:relative;
  margin-top:40px; }

.list-section-brands__footer-title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  margin-bottom:15px; }

.list-section-brands__footer-list-item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  display:inline; }

@media screen and (min-width: 600px){
  .list-section-brands__container{
    position:relative; }
    .list-section-brands__container:before{
      content:'';
      background-image:url("data:image/svg+xml,%3Csvg width='747' height='1011' viewBox='0 0 747 1011' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' d='M373.5 0C373.5 0 0 480.55 0 669.215C0 759.862 39.3508 846.796 109.396 910.893C179.44 974.991 274.442 1011 373.5 1011C472.558 1011 567.56 974.991 637.604 910.893C707.649 846.796 747 759.862 747 669.215C747 480.55 373.5 0 373.5 0ZM373.5 812.309C352.04 812.369 330.778 808.553 310.933 801.079C291.088 793.606 273.05 782.622 257.852 768.757C242.655 754.893 230.597 738.419 222.369 720.282C214.142 702.145 209.907 682.701 209.907 663.063C209.907 580.579 373.002 344.064 373.002 344.064C373.002 344.064 536.097 581.262 536.097 663.063C536.097 702.567 518.982 740.459 488.504 768.435C458.025 796.411 416.669 812.188 373.5 812.309Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat:no-repeat;
      background-position:center;
      background-size:contain;
      position:absolute;
      width:747px;
      height:1011px;
      left:-313px;
      bottom:-40px; }
  .list-section-brands__item-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; }
  .list-section-brands__footer-list-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 768px){
  .list-section-brands__item-title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; }
  .list-section-brands__item-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; }
  .list-section-brands__footer{
    margin-top:60px; }
  .list-section-brands__footer-title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px; } }

@media screen and (min-width: 1200px){
  .list-section-brands__container:before{
    bottom:-150px; } }

@media screen and (min-width: 1600px){
  .list-section-brands__container:before{
    left:-120px; }
  .list-section-brands__footer{
    margin-top:120px; } }

.lider-position{
  padding-top:150px;
  padding-bottom:150px;
  color:#fff;
  background-color:#000;
  background-image:url("../img/common/common-bg.jpg");
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover; }

.lider-position__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  position:relative;
  padding-top:26px;
  margin-bottom:20px; }
  .lider-position__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.lider-position__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  margin-bottom:30px; }

.lider-position__text p{
  margin-bottom:30px; }
  .lider-position__text p:last-child{
    margin-bottom:0; }

.lider-position__author{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px; }

@media screen and (min-width: 600px){
  .lider-position__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px; }
  .lider-position__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; }
  .lider-position__author{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; } }

@media screen and (min-width: 768px){
  .lider-position{
    padding-top:200px;
    padding-bottom:200px; }
  .lider-position__row{
    display:flex; }
  .lider-position__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    width:300px;
    flex-shrink:0;
    margin-bottom:0; }
  .lider-position__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px;
    margin-bottom:60px; }
  .lider-position__author{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:18px;
    line-height:25px; } }

@media screen and (min-width: 1200px){
  .lider-position__title{
    margin-right:70px; } }

@media screen and (min-width: 1921px){
  .lider-position__title{
    margin-right:170px; } }

.image-section{
  position:relative;
  width:100%;
  height:260px; }
  .image-section_pos_top .image-section__image{
    -o-object-position:center top;
       object-position:center top; }

.image-section__image-wrap{
  width:100%;
  height:100%; }
  .image-section__image-wrap > img{
    display:block;
    width:100%;
    height:100%;
    -o-object-fit:cover;
       object-fit:cover; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .image-section__image-wrap{
      overflow:hidden;
      display:flex;
      justify-content:center;
      align-items:center; }
      .image-section__image-wrap > img{
        height:auto; } }

@media screen and (min-width: 480px){
  .image-section{
    height:400px; } }

@media screen and (min-width: 600px){
  .image-section{
    height:500px; } }

@media screen and (min-width: 768px){
  .image-section{
    height:640px; } }

.company-values{
  padding-top:80px;
  padding-bottom:80px;
  background-color:#F7C449;
  background-image:linear-gradient(107.62deg, #FFC230 36.78%, #FDB54A 77.9%); }

.company-values__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  position:relative;
  padding-top:26px;
  color:#292929;
  margin-bottom:30px; }
  .company-values__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.company-values__grid{
  overflow:hidden; }
  .company-values__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-25px -25px; }
  .company-values__grid-item{
    width:calc(100% - 25px*2);
    margin:25px 25px; }

.company-values__item-title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  color:#292929;
  margin-bottom:10px; }

.company-values__item-text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:18px;
  line-height:25px;
  color:#292929; }

@media screen and (min-width: 600px){
  .company-values{
    position:relative;
    padding-top:100px;
    padding-bottom:100px; }
    .company-values:before{
      content:'';
      position:absolute;
      width:958px;
      height:1295px;
      left:-608px;
      top:50%;
      transform:translateY(-50%);
      background-color:transparent;
      background-image:url("data:image/svg+xml,%3Csvg width='1019' height='1386' viewBox='0 0 1019 1386' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M506.5 0C506.5 0 -6 658.795 -6 917.44C-6 1041.71 47.9954 1160.89 144.108 1248.76C240.22 1336.63 370.577 1386 506.5 1386C642.423 1386 772.78 1336.63 868.892 1248.76C965.005 1160.89 1019 1041.71 1019 917.44C1019 658.795 506.5 0 506.5 0ZM506.5 1113.61C477.054 1113.69 447.879 1108.46 420.649 1098.22C393.418 1087.97 368.667 1072.91 347.813 1053.9C326.96 1034.9 310.415 1012.31 299.125 987.449C287.836 962.585 282.025 935.928 282.025 909.006C282.025 795.927 505.817 471.684 505.817 471.684C505.817 471.684 729.608 796.864 729.608 909.006C729.609 963.163 706.124 1015.11 664.303 1053.46C622.482 1091.82 565.735 1113.45 506.5 1113.61Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat:no-repeat;
      background-position:center;
      background-size:contain; }
  .company-values__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px; }
  .company-values__item-title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:28px;
    line-height:35px; } }

@media screen and (min-width: 768px){
  .company-values{
    padding-top:150px;
    padding-bottom:150px; }
    .company-values:before{
      width:1065px;
      height:1440px;
      left:-653px; } }

@media screen and (min-width: 960px){
  .company-values:before{
    width:1025px;
    height:1386px;
    left:-616px; }
  .company-values__title{
    margin-bottom:55px; }
  .company-values__grid{
    overflow:hidden; }
    .company-values__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -10px; }
    .company-values__grid-item{
      width:calc(50% - 10px*2);
      margin:30px 10px; }
  .company-values__item-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 1200px){
  .company-values:before{
    left:-494px; }
  .company-values__title{
    margin-bottom:90px; }
  .company-values__grid{
    overflow:hidden; }
    .company-values__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -75px; }
    .company-values__grid-item{
      width:calc(50% - 75px*2);
      margin:30px 75px; } }

@media screen and (min-width: 1600px){
  .company-values:before{
    left:-290px; } }

@media screen and (min-width: 1921px){
  .company-values:before{
    left:-340px; }
  .company-values__grid{
    overflow:hidden; }
    .company-values__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-45px -75px; }
    .company-values__grid-item{
      width:calc(50% - 75px*2);
      margin:45px 75px; } }

@media screen and (min-width: 2600px){
  .company-values:before{
    left:0; } }

.team-training{
  padding-top:100px;
  padding-bottom:100px;
  color:#fff; }
  .team-training .team-training__grid{
    padding-top:10px; }

.team-training__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  position:relative;
  padding-top:26px;
  margin-bottom:30px;
  width:1000px;
  max-width:100%; }
  .team-training__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.team-training__grid{
  overflow:hidden; }
  .team-training__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-20px -20px; }
  .team-training__grid-item{
    width:calc(100% - 20px*2);
    margin:20px 20px; }

.team-training__grid-row{
  counter-reset:team-training-counter; }

.team-training__grid-item{
  position:relative;
  list-style:none;
  padding-left:35px; }
  .team-training__grid-item:before{
    content:"";
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain; }
  .team-training__grid-item:before{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px;
    content:counter(team-training-counter) ".";
    counter-increment:team-training-counter;
    top:9px;
    color:#F7C449; }

.team-training__item-title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  margin-bottom:10px; }

.team-training__item-text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px; }

@media screen and (max-width: 479px){
  .team-training__grid-item{
    padding-left:0; }
    .team-training__grid-item:before{
      display:block;
      position:static;
      transform:none; } }

@media screen and (min-width: 480px){
  .team-training__grid-item{
    padding-left:50px; }
    .team-training__grid-item:before{
      top:16px; } }

@media screen and (min-width: 600px){
  .team-training__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px; }
  .team-training__grid-item:before{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:46px;
    line-height:59px; }
  .team-training__item-title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:28px;
    line-height:35px;
    margin-bottom:15px; }
  .team-training__item-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:16px;
    line-height:24px; } }

@media screen and (min-width: 768px){
  .team-training__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    margin-bottom:70px; }
  .team-training__item-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 960px){
  .team-training{
    padding-top:120px;
    padding-bottom:120px; }
  .team-training__grid{
    overflow:hidden; }
    .team-training__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-15px -30px; }
    .team-training__grid-item{
      width:calc(50% - 30px*2);
      margin:15px 30px; } }

@media screen and (min-width: 1200px){
  .team-training__grid{
    overflow:hidden; }
    .team-training__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -30px; }
    .team-training__grid-item{
      width:calc(50% - 30px*2);
      margin:30px 30px; } }

.special-attention{
  padding-top:100px;
  padding-bottom:100px;
  background-color:#000;
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;
  color:#fff; }

.special-attention__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  position:relative;
  padding-top:26px;
  margin-bottom:30px; }
  .special-attention__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.special-attention__list-item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  position:relative;
  padding-left:50px;
  margin-bottom:30px; }
  .special-attention__list-item:before{
    content:"";
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain; }
  .special-attention__list-item:before{
    width:17px;
    height:21px;
    top:12px;
    background-image:url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 0C8.5 0 0 9.98174 0 13.9006C0 15.7835 0.895533 17.5892 2.48959 18.9206C4.08365 20.252 6.24566 21 8.5 21C10.7543 21 12.9163 20.252 14.5104 18.9206C16.1045 17.5892 17 15.7835 17 13.9006C17 9.98174 8.5 0 8.5 0ZM8.5 16.8729C8.01162 16.8741 7.52776 16.7949 7.07613 16.6396C6.6245 16.4844 6.21399 16.2562 5.86813 15.9683C5.52226 15.6803 5.24785 15.3381 5.06061 14.9614C4.87338 14.5846 4.777 14.1807 4.777 13.7728C4.777 12.0595 8.48867 7.14672 8.48867 7.14672C8.48867 7.14672 12.2003 12.0737 12.2003 13.7728C12.2003 14.5934 11.8108 15.3805 11.1172 15.9616C10.4236 16.5427 9.48243 16.8704 8.5 16.8729Z' fill='%23F7C449'/%3E%3C/svg%3E%0A"); }
  .special-attention__list-item:last-child{
    margin-bottom:0; }

@media screen and (min-width: 480px){
  .special-attention__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px; }
  .special-attention__list-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 768px){
  .special-attention{
    padding-top:120px;
    padding-bottom:120px; }
  .special-attention__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    margin-bottom:70px; }
  .special-attention__list-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; } }

@media screen and (min-width: 960px){
  .special-attention__row{
    display:flex; }
  .special-attention__title{
    width:370px;
    flex-shrink:0; }
  .special-attention__list{
    width:50%;
    margin-left:auto; } }

@media screen and (min-width: 1200px){
  .special-attention__title{
    width:490px; }
  .special-attention__list-item{
    margin-bottom:45px; }
    .special-attention__list-item:last-child{
      margin-bottom:0; } }

.candidate-examination{
  background-color:#000;
  background-image:url("../img/common/common-bg-2.jpg");
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;
  padding-top:100px;
  padding-bottom:100px;
  color:#fff; }

.candidate-examination__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  width:950px;
  max-width:100%;
  margin-bottom:30px; }

.candidate-examination__grid{
  overflow:hidden; }
  .candidate-examination__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-15px -15px; }
  .candidate-examination__grid-item{
    width:calc(100% - 15px*2);
    margin:15px 15px; }

.candidate-examination__grid-item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px; }

.candidate-examination__item-icon{
  display:block;
  width:42px;
  height:42px;
  background-repeat:no-repeat;
  background-position:center;
  background-size:contain;
  margin-bottom:15px; }

@media screen and (min-width: 480px){
  .candidate-examination__grid-item{
    position:relative;
    padding-left:66px; }
  .candidate-examination__item-icon{
    position:absolute;
    left:0;
    top:24px;
    transform:translateY(-50%);
    margin-bottom:0; } }

@media screen and (min-width: 600px){
  .candidate-examination__grid-item{
    padding-left:78px; }
  .candidate-examination__item-icon{
    width:54px;
    height:54px; } }

@media screen and (min-width: 768px){
  .candidate-examination{
    padding-top:120px;
    padding-bottom:120px; }
  .candidate-examination__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    margin-bottom:70px; }
  .candidate-examination__grid-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; }
  .candidate-examination__item-icon{
    top:38px; } }

@media screen and (min-width: 960px){
  .candidate-examination__grid{
    overflow:hidden; }
    .candidate-examination__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-40px -40px; }
    .candidate-examination__grid-item{
      width:calc(50% - 40px*2);
      margin:40px 40px; } }

@media screen and (min-width: 1921px){
  .candidate-examination__grid{
    overflow:hidden; }
    .candidate-examination__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-40px -70px; }
    .candidate-examination__grid-item{
      width:calc(50% - 70px*2);
      margin:40px 70px; } }

@media screen and (min-width: 2600px){
  .candidate-examination__grid{
    overflow:hidden; }
    .candidate-examination__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-40px -130px; }
    .candidate-examination__grid-item{
      width:calc(50% - 130px*2);
      margin:40px 130px; } }

.complete-cleaning{
  padding-top:80px;
  padding-bottom:80px;
  background-color:#FDC02E;
  color:#292929; }

.complete-cleaning__grid{
  overflow:hidden; }
  .complete-cleaning__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-15px -15px; }
  .complete-cleaning__grid-item{
    width:calc(100% - 15px*2);
    margin:15px 15px; }

.complete-cleaning__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  position:relative;
  padding-top:26px;
  margin-bottom:15px; }
  .complete-cleaning__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#fff; }

.complete-cleaning__subtitle,
.complete-cleaning__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px; }

@media screen and (min-width: 480px){
  .complete-cleaning{
    padding-top:100px;
    padding-bottom:100px; } }

@media screen and (min-width: 600px){
  .complete-cleaning__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px; }
  .complete-cleaning__subtitle,
  .complete-cleaning__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 768px){
  .complete-cleaning{
    padding-top:120px;
    padding-bottom:120px; }
  .complete-cleaning__grid{
    overflow:hidden; }
    .complete-cleaning__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -30px; }
    .complete-cleaning__grid-item{
      width:calc(100% - 30px*2);
      margin:30px 30px; }
  .complete-cleaning__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    margin-bottom:30px; }
  .complete-cleaning__subtitle{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:28px;
    line-height:35px; }
  .complete-cleaning__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:25px;
    line-height:38px; } }

@media screen and (min-width: 960px){
  .complete-cleaning__container:before{
    content:'';
    position:absolute;
    z-index:-1;
    background-image:url("data:image/svg+xml,%3Csvg width='788' height='1176' viewBox='0 0 788 1176' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M394 0C394 0 0 558.839 0 778.241C0 883.656 41.5106 984.753 115.4 1059.29C189.289 1133.83 289.505 1175.71 394 1175.71C498.495 1175.71 598.711 1133.83 672.6 1059.29C746.489 984.753 788 883.656 788 778.241C788 558.839 394 0 394 0ZM394 944.647C371.362 944.717 348.934 940.279 327.999 931.588C307.065 922.897 288.037 910.124 272.005 894C255.973 877.877 243.253 858.72 234.574 837.628C225.895 816.536 221.428 793.924 221.428 771.087C221.428 675.165 393.475 400.117 393.475 400.117C393.475 400.117 565.521 675.96 565.521 771.087C565.522 817.026 547.467 861.092 515.316 893.626C483.164 926.159 439.539 944.507 394 944.647Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain;
    width:788px;
    height:1176px;
    right:-50%;
    top:50%;
    transform:translateY(-50%); }
  .complete-cleaning__grid{
    overflow:hidden;
    margin-top:-26px; }
    .complete-cleaning__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-20px -20px; }
    .complete-cleaning__grid-item{
      width:calc(50% - 20px*2);
      margin:20px 20px; }
  .complete-cleaning__text{
    margin-top:26px; } }

@media screen and (min-width: 1200px){
  .complete-cleaning__container:before{
    right:-100px; } }

@media screen and (min-width: 1600px){
  .complete-cleaning__container:before{
    right:0; } }

@media screen and (min-width: 1921px){
  .complete-cleaning__grid{
    overflow:hidden; }
    .complete-cleaning__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-20px -40px; }
    .complete-cleaning__grid-item{
      width:calc(50% - 40px*2);
      margin:20px 40px; } }

.application-advantages{
  padding-top:80px;
  padding-bottom:80px;
  background-color:#FDC02E;
  color:#000;
  overflow:hidden; }

.application-advantages__container{
  position:relative; }
  .application-advantages__container:before{
    content:'';
    background-image:url("data:image/svg+xml,%3Csvg width='873' height='1138' viewBox='0 0 873 1138' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M436.651 0.199219C436.651 0.199219 0.856445 540.555 0.856445 752.7C0.856445 854.628 46.7704 952.382 128.498 1024.46C210.225 1096.53 321.071 1137.02 436.651 1137.02C552.231 1137.02 663.077 1096.53 744.805 1024.46C826.532 952.382 872.446 854.628 872.446 752.7C872.446 540.555 436.651 0.199219 436.651 0.199219ZM436.651 913.603C411.612 913.67 386.804 909.379 363.649 900.975C340.494 892.572 319.447 880.221 301.715 864.631C283.983 849.04 269.914 830.517 260.314 810.123C250.714 789.728 245.773 767.864 245.773 745.782C245.773 653.033 436.07 387.083 436.07 387.083C436.07 387.083 626.367 653.801 626.367 745.782C626.368 790.202 606.398 832.811 570.836 864.268C535.274 895.726 487.02 913.467 436.651 913.603Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain;
    position:absolute;
    left:-194px;
    bottom:-150px;
    width:503px;
    height:658px; }

.application-advantages__content{
  position:relative; }

.application-advantages__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px;
  margin-bottom:30px;
  width:700px;
  max-width:100%; }

.application-advantages__grid{
  overflow:hidden; }
  .application-advantages__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-8px -8px; }
  .application-advantages__grid-item{
    width:calc(100% - 8px*2);
    margin:8px 8px; }

.application-advantages__grid-item{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  position:relative;
  padding-left:40px; }
  .application-advantages__grid-item:before{
    content:"";
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain; }
  .application-advantages__grid-item:before{
    width:19px;
    height:4px;
    background-color:#fff;
    top:12px; }

@media screen and (min-width: 480px){
  .application-advantages{
    padding-top:100px;
    padding-bottom:100px; }
  .application-advantages__container:before{
    width:550px;
    height:720px;
    left:-270px;
    bottom:-200px; }
  .application-advantages__grid{
    overflow:hidden; }
    .application-advantages__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-8px -10px; }
    .application-advantages__grid-item{
      width:calc(50% - 10px*2);
      margin:8px 10px; } }

@media screen and (min-width: 600px){
  .application-advantages__container:before{
    width:700px;
    height:920px;
    left:-310px;
    bottom:-280px; }
  .application-advantages__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; }
  .application-advantages__grid{
    overflow:hidden; }
    .application-advantages__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-8px -16px; }
    .application-advantages__grid-item{
      width:calc(50% - 16px*2);
      margin:8px 16px; }
  .application-advantages__grid-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 768px){
  .application-advantages{
    padding-top:120px;
    padding-bottom:150px; }
  .application-advantages__container:before{
    width:879px;
    height:1149px;
    left:-170px;
    bottom:-300px; }
  .application-advantages__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    margin-bottom:70px; }
  .application-advantages__grid{
    overflow:hidden; }
    .application-advantages__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-12px -16px; }
    .application-advantages__grid-item{
      width:calc(50% - 16px*2);
      margin:12px 16px; }
  .application-advantages__grid-item{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:28px;
    line-height:35px; }
    .application-advantages__grid-item:before{
      top:17px; } }

@media screen and (min-width: 960px){
  .application-advantages{
    padding-bottom:200px; } }

@media screen and (min-width: 1921px){
  .application-advantages__container:before{
    left:100px; } }

.app-tutor{
  padding-top:80px;
  padding-bottom:80px; }

.app-tutor__grid{
  overflow:hidden; }
  .app-tutor__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-30px -30px; }
  .app-tutor__grid-item{
    width:calc(100% - 30px*2);
    margin:30px 30px; }

.app-tutor__grid-row{
  counter-reset:app-tutor-counter; }

.app-tutor__grid-item{
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  list-style:none; }
  .app-tutor__grid-item > *{
    max-width:100%; }

.app-tutor__image-wrap{
  width:209px;
  max-width:100%;
  height:452px;
  margin-bottom:20px; }
  .app-tutor__image-wrap > img{
    display:block;
    width:100%;
    height:100%;
    -o-object-fit:contain;
       object-fit:contain; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .app-tutor__image-wrap{
      display:flex;
      justify-content:center;
      align-items:center; }
      .app-tutor__image-wrap > img{
        height:auto; } }

.app-tutor__item-title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  color:#fff; }
  .app-tutor__item-title:before{
    content:counter(app-tutor-counter) ". ";
    counter-increment:app-tutor-counter; }

@media screen and (min-width: 480px){
  .app-tutor{
    padding-top:100px;
    padding-bottom:100px; }
  .app-tutor__image-wrap{
    margin-bottom:30px; }
  .app-tutor__item-title:before{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; } }

@media screen and (min-width: 600px){
  .app-tutor__grid{
    overflow:hidden; }
    .app-tutor__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -15px; }
    .app-tutor__grid-item{
      width:calc(50% - 15px*2);
      margin:30px 15px; } }

@media screen and (min-width: 768px){
  .app-tutor{
    padding-top:120px;
    padding-bottom:120px; }
  .app-tutor__grid{
    overflow:hidden; }
    .app-tutor__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-15px -15px; }
    .app-tutor__grid-item{
      width:calc(33.33% - 15px*2);
      margin:15px 15px; }
  .app-tutor__grid-item:nth-child(2) .app-tutor__image-wrap{
    margin-top:50px; }
  .app-tutor__grid-item:nth-child(3) .app-tutor__image-wrap{
    margin-top:100px; } }

@media screen and (min-width: 960px){
  .app-tutor__grid{
    overflow:hidden; }
    .app-tutor__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -30px; }
    .app-tutor__grid-item{
      width:calc(33.33% - 30px*2);
      margin:30px 30px; }
  .app-tutor__image-wrap{
    width:261px;
    height:565px; } }

@media screen and (min-width: 1200px){
  .app-tutor__grid{
    overflow:hidden; }
    .app-tutor__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-40px -40px; }
    .app-tutor__grid-item{
      width:calc(33.33% - 40px*2);
      margin:40px 40px; }
  .app-tutor__grid-item:nth-child(2) .app-tutor__image-wrap{
    margin-top:80px; }
  .app-tutor__grid-item:nth-child(3) .app-tutor__image-wrap{
    margin-top:160px; }
  .app-tutor__image-wrap{
    width:329px;
    height:712px; } }

@media screen and (min-width: 1921px){
  .app-tutor__grid{
    overflow:hidden; }
    .app-tutor__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-60px -60px; }
    .app-tutor__grid-item{
      width:calc(33.33% - 60px*2);
      margin:60px 60px; }
  .app-tutor__image-wrap{
    width:361px;
    height:781px;
    margin-bottom:40px; } }

.search-banner__content{
  width:450px;
  max-width:100%; }

.search-banner__search-field{
  margin-bottom:20px; }

.search-banner__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:14px;
  line-height:18px;
  margin-bottom:20px; }

.search-banner__results-item{
  margin-bottom:10px; }

.search-banner__back-btn{
  min-width:160px;
  margin-top:30px; }

@media screen and (min-width: 480px){
  .search-banner__back-btn{
    margin-top:40px; } }

.corporate-advantages{
  padding-top:80px;
  padding-bottom:80px;
  background-color:#F7C449;
  background-image:linear-gradient(107.62deg, #FFC230 36.78%, #FDB54A 77.9%); }

.corporate-advantages__grid{
  overflow:hidden; }
  .corporate-advantages__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-20px -20px; }
  .corporate-advantages__grid-item{
    width:calc(100% - 20px*2);
    margin:20px 20px; }

.corporate-advantages__item{
  width:450px;
  max-width:100%; }

.corporate-advantages__item-icon{
  display:block;
  width:62px;
  height:62px;
  background-repeat:no-repeat;
  background-position:center;
  background-size:contain;
  margin-bottom:30px; }

.corporate-advantages__item-title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:20px;
  line-height:29px;
  color:#000;
  margin-bottom:20px; }

.corporate-advantages__item-text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  color:#292929; }

@media screen and (min-width: 480px){
  .corporate-advantages{
    padding-top:100px;
    padding-bottom:100px; } }

@media screen and (min-width: 600px){
  .corporate-advantages__grid{
    overflow:hidden; }
    .corporate-advantages__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -30px; }
    .corporate-advantages__grid-item{
      width:calc(100% - 30px*2);
      margin:30px 30px; }
  .corporate-advantages__item-title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px;
    margin-bottom:30px; }
  .corporate-advantages__item-text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 768px){
  .corporate-advantages{
    padding-top:120px;
    padding-bottom:120px; } }

@media screen and (min-width: 960px){
  .corporate-advantages__grid{
    overflow:hidden; }
    .corporate-advantages__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -15px; }
    .corporate-advantages__grid-item{
      width:calc(50% - 15px*2);
      margin:30px 15px; } }

@media screen and (min-width: 1921px){
  .corporate-advantages__item{
    width:550px; } }

.our-clients{
  padding-top:80px;
  padding-bottom:80px;
  background-color:#000; }

.our-clients__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:36px;
  line-height:44px;
  position:relative;
  padding-top:26px;
  color:#fff;
  margin-bottom:30px; }
  .our-clients__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.our-clients__grid{
  overflow:hidden; }
  .our-clients__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-20px -20px; }
  .our-clients__grid-item{
    width:calc(50% - 20px*2);
    margin:20px 20px; }

.our-clients__item-image-wrap{
  width:110px;
  height:60px; }
  .our-clients__item-image-wrap > img{
    display:block;
    width:100%;
    height:100%;
    -o-object-fit:contain;
       object-fit:contain; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .our-clients__item-image-wrap{
      display:flex;
      justify-content:center;
      align-items:center; }
      .our-clients__item-image-wrap > img{
        height:auto; } }

@media screen and (min-width: 480px){
  .our-clients{
    padding-top:100px;
    padding-bottom:100px; } }

@media screen and (min-width: 600px){
  .our-clients__title{
    margin-bottom:70px; }
  .our-clients__grid{
    overflow:hidden; }
    .our-clients__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-20px -20px; }
    .our-clients__grid-item{
      width:calc(25% - 20px*2);
      margin:20px 20px; } }

@media screen and (min-width: 768px){
  .our-clients{
    padding-top:120px;
    padding-bottom:120px; }
  .our-clients__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px; }
  .our-clients__grid{
    overflow:hidden; }
    .our-clients__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-30px -30px; }
    .our-clients__grid-item{
      width:calc(25% - 30px*2);
      margin:30px 30px; }
  .our-clients__item-image-wrap{
    width:130px;
    height:70px; } }

@media screen and (min-width: 1921px){
  .our-clients__item-image-wrap{
    width:170px;
    height:90px; } }

.contacts-section{
  padding-top:80px;
  padding-bottom:80px;
  background-color:#000;
  background-image:url("../img/common/common-bg-2.jpg");
  background-repeat:no-repeat;
  background-position:center top;
  background-size:cover; }

.contacts-section__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:36px;
  line-height:44px;
  position:relative;
  padding-top:26px;
  color:#fff;
  margin-bottom:30px; }
  .contacts-section__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.contacts-section__grid{
  overflow:hidden;
  margin-bottom:70px; }
  .contacts-section__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-10px -10px; }
  .contacts-section__grid-item{
    width:calc(100% - 10px*2);
    margin:10px 10px; }

@media screen and (min-width: 480px){
  .contacts-section{
    padding-top:100px;
    padding-bottom:100px; } }

@media screen and (min-width: 600px){
  .contacts-section__grid{
    margin-bottom:100px; } }

@media screen and (min-width: 768px){
  .contacts-section{
    padding-top:120px;
    padding-bottom:120px; }
  .contacts-section__title{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px;
    margin-bottom:70px; } }

@media screen and (min-width: 960px){
  .contacts-section__grid{
    overflow:hidden; }
    .contacts-section__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-10px -10px; }
    .contacts-section__grid-item{
      width:calc(50% - 10px*2);
      margin:10px 10px; } }

@media screen and (min-width: 1200px){
  .contacts-section__grid{
    overflow:hidden; }
    .contacts-section__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-15px -15px; }
    .contacts-section__grid-item{
      width:calc(50% - 15px*2);
      margin:15px 15px; } }

.care-system{
  padding-top:80px;
  padding-bottom:80px;
  background-color:#000;
  background-image:url("../img/common/common-bg-2.jpg");
  background-repeat:no-repeat;
  background-position:center top;
  background-size:cover;
  color:#fff; }

.care-system__header{
  margin-bottom:80px; }

.care-system__title,
.care-system__title-mark{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:normal;
  font-size:28px;
  line-height:35px; }

.care-system__title{
  position:relative;
  padding-top:26px;
  margin-bottom:20px; }
  .care-system__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.care-system__title-mark{
  color:#F7C449; }

.care-system__text{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px; }

.care-system__grid{
  overflow:hidden; }
  .care-system__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-15px -0px; }
  .care-system__grid-item{
    width:calc(100% - 0px*2);
    margin:15px 0px; }

.care-system__grid-row{
  counter-reset:care-system-counter;
  list-style:none; }

.care-system__grid-item .care-step__title:after{
  content:counter(care-system-counter);
  counter-increment:care-system-counter; }

.care-system__grid-item:nth-child(1) .care-step__title:after,
.care-system__grid-item:nth-child(2) .care-step__title:after,
.care-system__grid-item:nth-child(3) .care-step__title:after,
.care-system__grid-item:nth-child(4) .care-step__title:after,
.care-system__grid-item:nth-child(5) .care-step__title:after,
.care-system__grid-item:nth-child(6) .care-step__title:after,
.care-system__grid-item:nth-child(7) .care-step__title:after,
.care-system__grid-item:nth-child(8) .care-step__title:after,
.care-system__grid-item:nth-child(9) .care-step__title:after{
  content:"0" counter(care-system-counter); }

@media screen and (min-width: 480px){
  .care-system{
    padding-top:100px;
    padding-bottom:100px; }
  .care-system__title{
    margin-bottom:25px; }
  .care-system__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:18px;
    line-height:25px; } }

@media screen and (min-width: 600px){
  .care-system__title,
  .care-system__title-mark{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:36px;
    line-height:44px; } }

@media screen and (min-width: 768px){
  .care-system{
    padding-top:120px;
    padding-bottom:120px; }
  .care-system__header{
    margin-bottom:100px; }
  .care-system__title,
  .care-system__title-mark{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:52px;
    line-height:56px; }
  .care-system__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:36px;
    line-height:44px; } }

@media screen and (min-width: 960px){
  .care-system__header{
    display:flex;
    align-items:flex-start; }
  .care-system__title{
    margin-right:55px; } }

@media screen and (min-width: 1200px){
  .care-system__header{
    margin-bottom:120px; } }

@media screen and (min-width: 1921px){
  .care-system__header{
    margin-bottom:150px; }
  .care-system__title{
    margin-right:110px; } }

.articles-grid__grid{
  overflow:hidden; }
  .articles-grid__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-10px -10px; }
  .articles-grid__grid-item{
    width:calc(100% - 10px*2);
    margin:10px 10px; }

@media screen and (min-width: 600px){
  .articles-grid__grid{
    overflow:hidden; }
    .articles-grid__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-10px -10px; }
    .articles-grid__grid-item{
      width:calc(50% - 10px*2);
      margin:10px 10px; } }

@media screen and (min-width: 960px){
  .articles-grid__grid{
    overflow:hidden; }
    .articles-grid__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-10px -10px; }
    .articles-grid__grid-item{
      width:calc(33.33% - 10px*2);
      margin:10px 10px; } }

.program-grid__box{
  display:flex;
  flex-wrap:wrap;
  margin-top:-24px;
  margin-bottom:-34px; }

.program-grid__item{
  position:relative;
  margin-top:24px;
  margin-bottom:24px;
  width:100%; }
  .program-grid__item:after{
    content:'';
    position:absolute;
    height:2px;
    width:18px;
    background-image:url("data:image/svg+xml,%3Csvg width='18' height='2' viewBox='0 0 18 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.8'%3E%3Ccircle cx='1' cy='1' r='1' fill='white'/%3E%3Ccircle cx='9' cy='1' r='1' fill='white'/%3E%3Ccircle cx='17' cy='1' r='1' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A"); }
  .program-grid__item:last-child:after{
    display:none; }

@media screen and (max-width: 599px){
  .program-grid__item:after{
    top:100%;
    transform:rotate(90deg) translateX(24px) translateY(9px); } }

@media screen and (min-width: 600px){
  .program-grid__box{
    margin:-12px -16px; }
  .program-grid__item{
    margin:12px 16px;
    width:calc(50% - 32px); }
    .program-grid__item:after{
      left:100%;
      top:50%;
      transform:translateX(16px) translateX(-50%) translateY(-50%); } }

@media screen and (min-width: 600px) and (max-width: 767px){
  .program-grid__item:nth-child(2n):after{
    display:none; } }

@media screen and (min-width: 768px){
  .program-grid__item{
    width:calc(33.33% - 32px); }
    .program-grid__item:nth-child(3n):after{
      display:none; } }

@media screen and (min-width: 768px) and (max-width: 959px){
  .program-grid__item{
    padding-left:10px;
    padding-right:10px; } }

.care-grid__section{
  padding-bottom:100px; }
  .care-grid__section:last-child{
    padding-bottom:0; }

@media screen and (min-width: 768px){
  .care-grid__section{
    border-bottom:1px solid rgba(255, 255, 255, 0.3);
    margin-bottom:20px; }
    .care-grid__section:last-child{
      border-bottom:none; } }

.contacts-grid__grid{
  overflow:hidden; }
  .contacts-grid__grid-row{
    display:flex;
    flex-wrap:wrap;
    margin:-12px -12px; }
  .contacts-grid__grid-item{
    width:calc(100% - 12px*2);
    margin:12px 12px; }

@media screen and (min-width: 600px){
  .contacts-grid__grid{
    overflow:hidden; }
    .contacts-grid__grid-row{
      display:flex;
      flex-wrap:wrap;
      margin:-18px -18px; }
    .contacts-grid__grid-item{
      width:calc(100% - 18px*2);
      margin:18px 18px; } }

@media screen and (min-width: 960px){
  .contacts-grid__grid-item:nth-child(2n){
    flex-direction:row; } }

.services-grid__section{
  margin-bottom:80px; }
  .services-grid__section:last-child{
    margin-bottom:0; }

.side-window{
  position:relative;
  background-color:rgba(49, 49, 49, 0.8);
  -webkit-backdrop-filter:blur(14px);
          backdrop-filter:blur(14px);
  overflow-y:auto; }

.side-window__close-btn{
  position:absolute;
  right:16px;
  top:16px;
  width:24px;
  height:24px;
  background-color:#fff;
  background-image:url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z' fill='%234F4F4F'/%3E%3C/svg%3E%0A");
  background-repeat:no-repeat;
  background-position:center;
  border-radius:50%;
  cursor:pointer;
  transition:all 0.1s linear; }

.side-window__body{
  padding-top:70px;
  padding-bottom:16px;
  padding-left:16px;
  padding-right:16px; }

@media screen and (min-width: 768px){
  .side-window__body{
    padding-left:32px;
    padding-right:96px; } }

@media screen and (min-width: 1440px){
  .side-window__body{
    padding-left:96px;
    padding-right:96px; } }

.content-inner{
  padding-top:20px;
  padding-bottom:20px;
  background-color:#000;
  background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../img/common/common-bg.jpg");
  background-repeat:no-repeat;
  background-position:center top;
  background-size:100% auto; }

.content-inner__placeholder{
  position:relative; }

@media screen and (min-width: 480px){
  .content-inner{
    padding-top:40px;
    padding-bottom:40px; } }

@media screen and (min-width: 768px){
  .content-inner{
    padding-top:60px;
    padding-bottom:60px; } }

.about-page__main-banner{
  margin-bottom:-60px; }
  .about-page__main-banner > *{
    padding-bottom:60px; }

.about-page__history-wrapper{
  background-color:transparent;
  background-image:url("../img/common/common-bg-2.jpg");
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover; }

.about-page__company-card{
  position:relative;
  z-index:1; }

.about-page__list-section,
.about-page__lider-position,
.about-page__image-section,
.about-page__company-values{
  overflow:hidden;
  transform:skewY(5deg); }
  .about-page__list-section > *, .about-page__list-section:before, .about-page__list-section:after,
  .about-page__lider-position > *,
  .about-page__lider-position:before,
  .about-page__lider-position:after,
  .about-page__image-section > *,
  .about-page__image-section:before,
  .about-page__image-section:after,
  .about-page__company-values > *,
  .about-page__company-values:before,
  .about-page__company-values:after{
    transform:skewY(-5deg); }

.about-page__list-section{
  margin-top:-100px; }
  .about-page__list-section > *{
    padding-top:100px; }

.about-page__image-section-image-wrap{
  position:absolute;
  height:auto;
  top:-8.74887vw;
  bottom:-8.74887vw; }

.about-page__company-values{
  overflow:visible; }
  .about-page__company-values:before{
    top:calc(50% - 4.37443vw);
    transform:skewY(-5deg) translateY(-50%); }

@media screen and (min-width: 480px){
  .about-page__list-section{
    margin-top:-160px; }
    .about-page__list-section > *{
      padding-top:160px; } }

@media screen and (min-width: 600px){
  .about-page__main-banner{
    margin-bottom:-160px; }
    .about-page__main-banner > *{
      padding-bottom:160px; } }

@media screen and (max-width: 767px){
  .about-page__image-section{
    display:none; } }

@media screen and (min-width: 768px){
  .about-page__main-banner{
    margin-bottom:-190px; }
    .about-page__main-banner > *{
      padding-bottom:190px; }
  .about-page__list-section{
    margin-top:-260px; }
    .about-page__list-section > *{
      padding-top:260px; } }

@media screen and (min-width: 960px){
  .about-page__main-banner{
    margin-bottom:-210px; }
    .about-page__main-banner > *{
      padding-bottom:210px; } }

@media screen and (min-width: 1200px){
  .about-page__history-wrapper{
    background-position:center bottom;
    background-size:auto; } }

.team-page__training-wrapper{
  overflow:hidden;
  position:relative;
  background-color:#000;
  background-image:url("../img/components/team-page/training-wrapper-bg.jpg");
  background-repeat:no-repeat;
  background-position:center top;
  background-size:auto 1118px; }
  .team-page__training-wrapper:before{
    content:'';
    background-color:transparent;
    background-image:url("data:image/svg+xml,%3Csvg width='1038' height='1377' viewBox='0 0 1038 1377' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.15' filter='url(%23filter0_d)'%3E%3Cpath d='M519 30C519 30 40 646.492 40 888.528C40 1004.82 90.4659 1116.35 180.296 1198.57C270.126 1280.8 391.961 1327 519 1327C646.039 1327 767.874 1280.8 857.704 1198.57C947.534 1116.35 998 1004.82 998 888.528C998 646.492 519 30 519 30ZM519 1072.1C491.479 1072.18 464.211 1067.28 438.76 1057.7C413.31 1048.11 390.176 1034.02 370.686 1016.23C351.196 998.443 335.732 977.31 325.18 954.042C314.629 930.774 309.198 905.829 309.198 880.636C309.198 774.818 518.361 471.395 518.361 471.395C518.361 471.395 727.525 775.695 727.525 880.636C727.525 931.315 705.576 979.926 666.488 1015.82C627.4 1051.71 574.363 1071.95 519 1072.1Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='1038' height='1377' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='10'/%3E%3CfeGaussianBlur stdDeviation='20'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'/%3E%3CfeBlend mode='multiply' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain;
    position:absolute;
    width:467px;
    height:633px;
    left:-298px;
    top:172px; }

.team-page__company-card-1{
  position:relative; }

.team-page__individual-wrapper{
  background-color:#000;
  background-image:url("../img/common/common-bg-2.jpg");
  background-repeat:no-repeat;
  background-position:center top;
  background-size:cover; }

.team-page__list-section-2{
  background:none; }

@media screen and (min-width: 600px){
  .team-page__training-wrapper:before{
    width:626px;
    height:848px;
    left:-277px;
    top:85px; } }

@media screen and (min-width: 768px){
  .team-page__training-wrapper:before{
    top:48px; } }

@media screen and (min-width: 960px){
  .team-page__special-attention{
    margin-bottom:-150px; }
    .team-page__special-attention > *{
      padding-bottom:150px; }
  .team-page__candidate-examination{
    margin-top:-150px; }
    .team-page__candidate-examination > *{
      padding-top:150px; } }

@media screen and (min-width: 1200px){
  .team-page__training-wrapper:before{
    width:958px;
    height:1297px;
    left:-247px;
    top:-100px; }
  .team-page__special-attention{
    margin-bottom:-200px; }
    .team-page__special-attention > *{
      padding-bottom:200px; }
  .team-page__candidate-examination{
    margin-top:-200px; }
    .team-page__candidate-examination > *{
      padding-top:200px; } }

@media screen and (min-width: 1921px){
  .team-page__special-attention{
    margin-bottom:-250px; }
    .team-page__special-attention > *{
      padding-bottom:250px; }
  .team-page__candidate-examination{
    margin-top:-250px; }
    .team-page__candidate-examination > *{
      padding-top:250px; } }

@media screen and (min-width: 2600px){
  .team-page__training-wrapper:before{
    left:195px; } }

.selection-page__complete-cleaning{
  overflow:hidden;
  transform:skewY(5deg); }
  .selection-page__complete-cleaning > *, .selection-page__complete-cleaning:before, .selection-page__complete-cleaning:after{
    transform:skewY(-5deg); }

.application-page__main-banner-1{
  margin-bottom:-200px; }

.application-page__application-advantages{
  overflow:hidden;
  transform:skewY(5deg); }
  .application-page__application-advantages > *, .application-page__application-advantages:before, .application-page__application-advantages:after{
    transform:skewY(-5deg); }

.application-page__company-card{
  position:relative;
  z-index:1; }

.application-page__tutor-wrapper{
  background-color:#000;
  background-image:url("../img/common/common-bg-2.jpg");
  background-repeat:no-repeat;
  background-position:center top;
  background-size:cover; }

.application-page__main-banner-2{
  overflow:hidden;
  transform:skewY(-5deg);
  margin-top:-200px; }
  .application-page__main-banner-2 > *, .application-page__main-banner-2:before, .application-page__main-banner-2:after{
    transform:skewY(5deg); }
  .application-page__main-banner-2 > *{
    padding-top:200px; }

.application-page__main-banner-2 > *{
  margin-bottom:-250px; }

.application-page__main-banner-2 .main-banner__title{
  position:relative;
  padding-top:26px; }
  .application-page__main-banner-2 .main-banner__title:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:94px;
    height:6px;
    background-color:#F7C449; }

.application-page__want-know > *{
  padding-top:4.37443vw; }

@media screen and (min-width: 600px){
  .application-page__main-banner-2{
    margin-top:-250px; }
    .application-page__main-banner-2 > *{
      padding-top:250px; }
  .application-page__main-banner-2 > *{
    margin-bottom:-300px; } }

@media screen and (min-width: 768px){
  .application-page__main-banner-1{
    margin-bottom:-350px; }
  .application-page__main-banner-2{
    margin-top:-350px; }
    .application-page__main-banner-2 > *{
      padding-top:350px; }
  .application-page__main-banner-2 > *{
    margin-bottom:-400px; }
  .application-page__main-banner-2 .main-banner__text{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:20px;
    line-height:29px; } }

@media screen and (min-width: 960px){
  .application-page__main-banner-1 .main-banner-application__grid-row{
    margin-top:-80px; }
  .application-page__main-banner-2{
    margin-top:-300px; }
    .application-page__main-banner-2 > *{
      padding-top:300px; }
  .application-page__main-banner-2 > *{
    margin-bottom:-300px; } }

@media screen and (min-width: 1921px){
  .application-page__main-banner-2 .main-banner-application__buttons{
    margin-top:80px; } }

.search-page__search-banner{
  min-height:100vh; }

.corporate-page__want-know{
  padding-top:0; }

.technology-page__main-banner-2 .main-banner__content{
  width:500px;
  max-width:100%; }

.page.overlay{
  overflow:hidden; }

.page.overlay .page__overlay{
  display:block; }

.page__service-detail{
  position:fixed;
  z-index:11;
  left:0;
  top:0;
  width:680px;
  height:100vh;
  max-width:100%;
  transform:translateX(-100%);
  transition:all 0.3s; }
  .page__service-detail.active{
    transform:none; }

.page__overlay{
  position:fixed;
  z-index:10;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(20, 20, 20, 0.69);
  -webkit-backdrop-filter:blur(4px);
          backdrop-filter:blur(4px);
  display:none; }

@media screen and (min-width: 1440px){
  .page__service-detail{
    width:780px; } }

.machineheads{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:16px;
  position:absolute;
  top:33px;
  right:-100px;
  display:flex;
  align-items:center;
  justify-content:flex-end;
  color:#333;
  transform:rotate(-90deg) translate(-50%, -50%); }
  @media screen and (min-width: 480px){
    .machineheads{
      right:-90px; } }
  @media screen and (min-width: 768px){
    .machineheads{
      top:0;
      right:-85px; } }

.machineheads__img{
  margin:0 5px; }

.call-form{
  position:fixed;
  left:0;
  bottom:0;
  width:100%;
  padding:30px 24px;
  z-index:1;
  background:rgba(22, 22, 22, 0.97);
  -webkit-backdrop-filter:blur(14px);
          backdrop-filter:blur(14px);
  transform:translate3d(0, 100%, 0);
  transition:transform 0.3s linear; }
  @media screen and (min-width: 768px){
    .call-form{
      display:block;
      width:auto;
      transform:translate3d(-100%, 0, 0); } }
  .call-form.is-active{
    transform:translate3d(0, 0, 0); }
    .call-form.is-active .call-form__open-button{
      opacity:0; }
      @media screen and (min-width: 768px){
        .call-form.is-active .call-form__open-button{
          opacity:1; } }

.call-form__title{
  font-family:Gotham Pro, sans-serif;
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:24px;
  margin-bottom:15px;
  color:#fff; }

.call-form__button-close{
  position:absolute;
  right:10px;
  top:10px;
  width:20px;
  height:20px;
  background:transparent url(../img/icon/close-white.svg) 50% 50% no-repeat;
  cursor:pointer; }

.call-form__open-button{
  position:fixed;
  display:flex;
  left:10px;
  bottom:calc(100% + 10px);
  width:48px;
  height:48px;
  background:#F7C449 url(../img/icon/phone-call.svg) 50% 50% no-repeat;
  box-shadow:0px 4px 19px rgba(0, 0, 0, 0.36);
  border-radius:50%;
  transition:0.2s linear;
  cursor:pointer; }
  @media screen and (min-width: 768px){
    .call-form__open-button{
      justify-content:center;
      align-items:center;
      left:100%;
      top:0;
      height:100%;
      width:30px;
      border-radius:0 20px 0 0;
      background-image:none;
      box-shadow:none; } }
  .call-form__open-button span{
    font-family:Gotham Pro, sans-serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:18px;
    display:none; }
    @media screen and (min-width: 768px){
      .call-form__open-button span{
        display:block;
        top:50%;
        left:50%;
        transform:rotate(180deg);
        -webkit-writing-mode:tb-rl;
            -ms-writing-mode:tb-rl;
                writing-mode:tb-rl; } }

.scroll-button{
  display:none;
  position:absolute;
  left:calc(100% - 31px);
  top:80vh;
  background:#808080 linear-gradient(transparent 0%, transparent 50%, #F7C449 50%, #F7C449 100%);
  width:5px;
  height:40px;
  border-radius:100px;
  background-size:100% 200%;
  -webkit-animation:colorSlide 6s linear infinite, nudgeMouse 7s ease-out infinite;
  animation:colorSlide 6s linear infinite, nudgeMouse 7s ease-out infinite;
  z-index:1; }
  @media screen and (min-width: 768px){
    .scroll-button{
      display:block; } }
  .scroll-button:before, .scroll-button::after{
    content:"";
    position:absolute;
    top:auto;
    right:0;
    bottom:0;
    left:0;
    margin:auto; }

.scroll-button:after{
  bottom:-9px;
  left:calc(50% - 7px);
  width:0;
  height:0;
  border-style:solid;
  border-width:10px 7px 0 7px;
  border-color:#F7C449 transparent transparent transparent; }

@-webkit-keyframes colorSlide{
  0%{
    background-position:0% 100%; }
  20%{
    background-position:0% 0%; }
  21%{
    background-color:#141414; }
  29.99%{
    background-color:#F7C449;
    background-position:0% 0%; }
  30%{
    background-color:#141414;
    background-position:0% 100%; }
  50%{
    background-position:0% 0%; }
  51%{
    background-color:#141414; }
  59%{
    background-color:#F7C449;
    background-position:0% 0%; }
  60%{
    background-color:#141414;
    background-position:0% 100%; }
  80%{
    background-position:0% 0%; }
  81%{
    background-color:#141414; }
  90%, 100%{
    background-color:#F7C449; } }

@keyframes colorSlide{
  0%{
    background-position:0% 100%; }
  20%{
    background-position:0% 0%; }
  21%{
    background-color:#141414; }
  29.99%{
    background-color:#F7C449;
    background-position:0% 0%; }
  30%{
    background-color:#141414;
    background-position:0% 100%; }
  50%{
    background-position:0% 0%; }
  51%{
    background-color:#141414; }
  59%{
    background-color:#F7C449;
    background-position:0% 0%; }
  60%{
    background-color:#141414;
    background-position:0% 100%; }
  80%{
    background-position:0% 0%; }
  81%{
    background-color:#141414; }
  90%, 100%{
    background-color:#F7C449; } }

@-webkit-keyframes trackBallSlide{
  0%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  6%{
    opacity:1;
    transform:scale(0.9) translateY(2.5px); }
  14%{
    opacity:0;
    transform:scale(0.4) translateY(20px); }
  15%, 19%{
    opacity:0;
    transform:scale(0.4) translateY(-10px); }
  28%, 29.99%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  30%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  36%{
    opacity:1;
    transform:scale(0.9) translateY(2.5px); }
  44%{
    opacity:0;
    transform:scale(0.4) translateY(20px); }
  45%, 49%{
    opacity:0;
    transform:scale(0.4) translateY(-10px); }
  58%, 59.99%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  60%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  66%{
    opacity:1;
    transform:scale(0.9) translateY(2.5px); }
  74%{
    opacity:0;
    transform:scale(0.4) translateY(20px); }
  75%, 79%{
    opacity:0;
    transform:scale(0.4) translateY(-10px); }
  88%, 100%{
    opacity:1;
    transform:scale(1) translateY(-10px); } }

@keyframes trackBallSlide{
  0%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  6%{
    opacity:1;
    transform:scale(0.9) translateY(2.5px); }
  14%{
    opacity:0;
    transform:scale(0.4) translateY(20px); }
  15%, 19%{
    opacity:0;
    transform:scale(0.4) translateY(-10px); }
  28%, 29.99%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  30%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  36%{
    opacity:1;
    transform:scale(0.9) translateY(2.5px); }
  44%{
    opacity:0;
    transform:scale(0.4) translateY(20px); }
  45%, 49%{
    opacity:0;
    transform:scale(0.4) translateY(-10px); }
  58%, 59.99%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  60%{
    opacity:1;
    transform:scale(1) translateY(-10px); }
  66%{
    opacity:1;
    transform:scale(0.9) translateY(2.5px); }
  74%{
    opacity:0;
    transform:scale(0.4) translateY(20px); }
  75%, 79%{
    opacity:0;
    transform:scale(0.4) translateY(-10px); }
  88%, 100%{
    opacity:1;
    transform:scale(1) translateY(-10px); } }
